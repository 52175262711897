import ConfigController from "../../Config/controller/ConfigController";
import Controller from "../../Controller"

export default class LoginScreenController extends Controller {

    public route?: string = `oauth/user?sessionToken=${Controller.getCookies("sessionToken")}`;
    public loginWindow: any;
    public showErrorMessage: boolean = false
    public errorReson!: string;

    constructor() {
        super();
    }

    async login() {
        let state = Math.random().toString(36).substr(2);
        document.cookie = `state=${state}`
        window.location.replace(`${this.getURL()}oauth/login?state=${state}`);
    }

    loginURL() {
        if (!Controller.getCookies("user")) {
            this.login();
        }
    }

    async checkUser() {
        let data: unknown;
        if (Controller.getCookies("user") != null || Controller.getCookies("user")) {
            if (Controller.getCookies("sessionToken") != null || Controller.getCookies("sessionToken")) {
                try {

                    let resolveResponse = (response: any) : any => {
                        if(response.code === "error") {
                            throw new Error(response.responseData.message);
                        }
                        
                        data = response.responseData.data;
                        console.log(response.responseData.data);
                    }
    
                    let checkResponse = (response : any) : any =>{
                        if (response.ok) {
                            return response.json();
                        }
                        throw response
                    }

                    await fetch(this.getURL() + this.route, {
                        method: "GET",
                    })
                    .then(checkResponse)
                    .then(resolveResponse)
                    .catch((error : any) => {
                        throw new Error(error);
                    })
                    // data = await this.sendRequest({
                    //     url: `${this.getURL()}oauth/token?sessionToken=${this.getCookies("sessionToken")}`,
                    //     method: "GET"
                    // }).then((data) => {

                    // })
                } catch (error: any) {
                    this.errorReson = error.message;
                    this.showErrorMessage = true;
                    this.login();
                }
            } else {
                this.login();
            }
        }
        return data;
    }

}