import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import Config from "../Config";

interface IConfigData {
    endpoint : string
}

export default class ConfigController extends Controller {
    constructor() {
        super();
    }

    static getConfigData() : IConfigData {
        let output : IConfigData = {
            endpoint : localStorage.getItem("endpoint") || "https://staging.foundryilotterydev.com/"
        }
        return output
    }

    setConfigData(data : any) {
        localStorage.setItem("endpoint",data.endpoint);
    }

    generateGlobals() {
        this.route = 'admin/globals';
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "OPTIONS"},
            ).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    menu() : menuEntry {
        return {
            key: "configMenu",
            name: "Config",
            color : "#ff5741",
            style : "settingsGroupMenu",
            link: "/config",
        }
    }

    content() : contextEntry {
        return {
            key:"configContext",
            title: "Config",
            content: <Config />,
            path : "/config"
        }
    }
}