import './scss/styles.scss'
import * as bootstrap from 'bootstrap'
import { createRoot } from 'react-dom/client';
import MainContainer  from './components/root/MainContainer';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter, useNavigate } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

library.add(fas)

document.body.innerHTML = '<div id="app"></div>';

function render() {
    return (
        <BrowserRouter><MainContainer /></BrowserRouter>
    )
}

const root = createRoot(document.getElementById('app') as any);
root.render(render());

