import Controller from "../../Controller";
import { IForceData } from "../../GameList/data/GameListData";
import { contextEntry, menuEntry } from "../../IComponent";
import { IResponse } from "../../root/data/MainContainerData";
import Players from "../Players";
import { IPlayerHandelerOptions } from "../data/PlayersData";
import ForceData from "../views/ForceData";
import PlayerGameplayOptions from "../views/GamePlayOptions";

export default class PlayersController extends Controller {
    public tempPlayers: any = [];
    public permPlayers: any = [];
    public data: any = [];

    constructor() {
        super();
        this.route = "admin/player";

        this.subpageData = [
            {
                key: "forceDataContext",
                title: "forceData",
                content: <ForceData />,
                path: "/players/force_data/:id"
            },
            {
                key: "playerGameplayOptionsContext",
                title: "playerGameplayOptions",
                content: <PlayerGameplayOptions />,
                path: "/players/gameplay_options/:id"
            }
        ] as contextEntry[]
    }

    public async getData() {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "GET", full:false}).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }

    public async getPlayer(id : string) {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "GET", params: id}).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }

    public deletePlayer(id: string): Promise<unknown> {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "DELETE", params : id}).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }

    public createPlayer(id: string): Promise<unknown> {
        return new Promise(async(resolve,reject)=>{
            await this.sendRequest({method : "POST", params : id}).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        }) 
    }

    public getPlayerProperties(id : string) {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "GET", url : "properties", params: id}).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }

    public updatePlayerProperties(id : string, newData : any) {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "POST", url : "properties", params: id},{
                properties: newData
            }).then((data)=>{
                return resolve(data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }

    public purgeOldTempPlayers() {
        return new Promise(async (resolve,reject) => {
            await this.sendRequest({method : "DELETE"},{
            }).then((data: IResponse)=>{
                return resolve(data.responseData.data);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }


    public parsePlayers(data: any) : {
        "tempPlayers" : [],
        "permPlayers" : []
    } {
        let tempPlayers: any = [];
        let permPlayers: any = [];
        if(data.length > 0) {
            data.forEach((e: any) => {
                if (e.tempPlayer) {
                    tempPlayers.push(e);
                } else {
                    permPlayers.push(e);
                }
            });
        }
        return {
            "tempPlayers": tempPlayers,
            "permPlayers": permPlayers
        }
    }

     public async handleDelete(callbackData: any, options:IPlayerHandelerOptions) {
        let body: string = "";
        let success: boolean;

        await this.deletePlayer(callbackData[0].data)
            .then((data: any) => {
                body = data.responseData.data;
                success = true;
            })
            .catch((data: any) => {
                body = data.responseData.data;
                success = false;
            })
            .finally(() => {
                this.sendToastMessage({
                    body: body,
                    success: success
                });
                options.setAnchorEl!(null);
                options.setShowDeleteModal!(false);
                options.setReady!(false);
            });
    }

    public async handelNewPlayerCreateSubmit(callbackData: any,options:IPlayerHandelerOptions) {
        let body: string = "";
        let success: boolean;
        await this.createPlayer(callbackData[0].data)
            .then((data: any) => {
                body = data.responseData.data;
                success = true;
            })
            .catch((data: any) => {
                body = data.responseData.data;
                success = false;
            })
            .finally(() => {
                this.sendToastMessage({
                    body: body,
                    success: success
                });
                options.setAnchorEl!(null);
                options.setShowNewPlayerForm!(false);
                options.setReady!(false);
            })
    }

    public async handelPurgeOldTempPlayers(options:IPlayerHandelerOptions) {
        let body: string = "";
        let success: boolean;
        await this.purgeOldTempPlayers()
            .then((data: any) => {
                body = data;
                success = true;
            })
            .catch((data: any) => {
                body = data;
                success = false;
            })
            .finally(() => {
                this.sendToastMessage({
                    body: body,
                    success: success
                });
                options.setAnchorEl!(null);
                options.setShowPurgeModal!(false);
                options.setReady!(false);
            })
    }

    menu(): menuEntry {
        return {
            key: "playersMenu",
            name: "Players",
            color: "#ff5741",
            style: "settingsGroupMenu",
            link: "/players",
        }
    }

    content(): contextEntry {
        return {
            key: "playersContext",
            title: "Players",
            content: <Players />,
            path: "/players"
        }
    }

}