import GameListController from "../GameList/controller/GameListController";
import React, { useEffect, useState } from "react";
import { Container, Alert, Button, Grid, Autocomplete, TextField, IconButton, List, ListItem, ListItemText, Typography, CardContent, CardActions, Card, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayersController from '../Players/controller/PlayersController';
import { useParams } from 'react-router-dom';
import GaffController from './controller/GaffController';
import { ModalTemplate } from "../instances/modal";
import { formTemplate } from "../instances/form";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Star } from "@mui/icons-material";
import { IMechanic, IScenario } from "../GameList/data/GameListData";

export default function Gaff() {
    const gameListController: GameListController = new GameListController();
    const playerController: PlayersController = new PlayersController();
    const gaffController: GaffController = new GaffController();

    let gamesListData: any;
    let setGamesListData: any;
    [gamesListData, setGamesListData] = useState([]);

    let selectedGameID: number | null = null;
    let setSelectedGameID: any;
    [selectedGameID, setSelectedGameID] = useState(Number);

    let selectedGameData: string | null = null;
    let setSelectedGameData: any;
    [selectedGameData, setSelectedGameData] = useState(String);

    let [selectedGameTitle, setSelectedGameTitle] = useState(String);

    let selectedMechanicID: number | null = null;
    let setSelectedMechanicID: any;
    [selectedMechanicID, setSelectedMechanicID] = useState(null);

    let selectedGaffData: any;
    let setSelectedGaffData: any;
    [selectedGaffData, setSelectedGaffData] = useState({});

    let gaffListData: any | null = null;
    let setGaffListData: any;
    [gaffListData, setGaffListData] = useState([]);

    const [scenarios, setScenarioList] = useState<IScenario[] | []>([]);

    let [mechanicsListOpen, setMechanicsListOpen] = useState(false);

    let [showGaffCreationForm, setShowGaffCreationForm] = useState(false);
    let [showGaffConfirmForm, setShowGaffConfirmForm] = useState(false);;
    let [deleteGaffConfirmForm, setDeleteGaffConfirmForm] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>, element: any) => {
        setSelectedGaffData(element);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [ready, setReady] = useState(false);

    const params: any = useParams();
    let playerID: string = params.id

    useEffect(() => {
        if (!ready) {
            if (gamesListData.length === 0) {
                const getData = async () => {
                    await getGameData();
                }
                getData();
            }
        }
        if ((selectedGaffData._id && selectedGameID != 0) && selectedMechanicID === null) {
            const getMechanicListData = async () => {
                await getSelectedMechanics();
            }
            getMechanicListData();
        }

        if (selectedGameID != 0) {
            const getGaffListData = async () => {
                await getGaffListForGame();
            }
            getGaffListData();
        }
        setReady(true);
    }, [ready, selectedGaffData, selectedGameID, selectedMechanicID])

    function getGameData(): Promise<void> {
        return new Promise(async (resolve) => {
            let data = await gameListController.getData();
            setGamesListData(data as []);
            resolve();
        })
    }

    function getSelectedMechanics(): Promise<void> {
        return new Promise(async (resolve) => {
            await gameListController.getMechanic(selectedGameID,0).then((data)=>{
                setScenarioList([...((data as {results : IScenario[], total: number}).results)]);
            }) as {results : IScenario[], total: number};
            resolve();
        })
    }

    function getGaffListForGame(): Promise<void> {
        return new Promise(async (resolve) => {
            let data = await gaffController.getGameGaffData(selectedGameID);
            setGaffListData(data as []);
            resolve();
        })
    }

    function setGameData(data: any) {
        setSelectedGameID(data.id);
        setSelectedGameTitle(data.name);

        setSelectedGaffData({});
        setSelectedMechanicID(null);
        setMechanicsListOpen(false);

        setScenarioList([]);

        setReady(false);
    }

    function gamesListSeclection() {
        return (
            <Grid item xs={8}>
                <h5 className=' p-2'>Game Name {selectedGameTitle != "" ? `> ${selectedGameTitle}` : ""}</h5>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={gamesListData.map((e: any) => { return e.name })}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Games" />}
                    onSelectCapture={(data) => {
                        if ((data.target as any).value !== selectedGameTitle) {
                            let foundData = gamesListData.find((e: any) => {
                                if (e.name === (data.target as any).value) {
                                    return e;
                                }
                            })

                            if (foundData !== undefined) {
                                setGameData(foundData);
                            }
                        }
                    }} />
            </Grid>
        )
    }

    function gaffActionList(element: any): JSX.Element {
        return (
            <React.Fragment><IconButton
                aria-label="more"
                id={element.id}
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(data) => { handleClick(data, element) }}
            >
                <MoreHorizIcon />
            </IconButton><Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                    <MenuItem id={element.id} key={`${element.id}_scenario_delete`} onClick={(data: any) => { setDeleteGaffConfirmForm(true) }}>Delete</MenuItem>
                    <MenuItem id={element.id} key={`${element.id}_scenario_rename`} onClick={(data: any) => { setDeleteGaffConfirmForm(true) }}>Rename</MenuItem>
                </Menu></React.Fragment>
        )
    }

    function gaffList() {
        return (
            <Grid item xs={6}>
                <Typography variant="h5" paddingTop={5}> Gaff List </Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {gaffListData.map((value: any) => (
                        <ListItem
                            key={`${value.name}_key`}
                            disableGutters
                            secondaryAction={
                                <React.Fragment>
                                    {gaffActionList(value)}
                                    <IconButton aria-label="Mechanics List" onClick={() => { setMechanicsListOpen(true); setSelectedGaffData(value) }}>
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </React.Fragment>
                            }
                        >
                            <ListItemText primary={`${value.name}`} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        )
    }

    function gaffMechanicList() {
        return (
            <Grid item xs={6}>
                <Typography variant="h5" paddingTop={5} paddingBottom={3}> Mechanics List </Typography>
                {scenarios.map((value: any) => {
                    return basicContainer(value);
                })}
            </Grid>
        )
    }

    function basicContainer(data: any) {

        let setOnGaff = (
            <React.Fragment>
                <IconButton aria-label="comment" onClick={() => {
                    setSelectedGameData(data.data);
                    setSelectedMechanicID(data._id);
                }}>
                    <SaveAltIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        )

        let unsetOnGaff = (
            <React.Fragment>
                <IconButton aria-label="comment">
                    <Star fontSize="large" />
                </IconButton>
            </React.Fragment>
        )


        let showmore = false;
        if (data.data.length <= 50) {
            showmore = true;
        }
        return (
            <Card sx={{
                minWidth: 275,
                p: 2,
                margin: 'auto',
                maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }} key={data._id.toString()} >
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={10}>
                            <strong><p>Division: {data.prizeDivision}</p></strong>{
                                (data.data.length > 50 && !showmore) ? data.data.substring(0, 35) : data.data}{
                                !showmore ? (<a>...</a>) : null
                            }
                        </Grid>
                        <Grid item>
                            {data._id === selectedGaffData.scenarioID ? unsetOnGaff : setOnGaff}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    function gaffCreationForm() {
        return (
            <ModalTemplate
                title={`Create new Gaff in : ${selectedGameTitle}`}
                body={``}
                confirmButtonString="Create"
                confirmType="success"
                form={
                    formTemplate({
                        formGroup: [[{
                            label: "Gaff Name",
                            id: "name",
                            feedbackSuccess: "This name looks ok!",
                            feedbackInvalid: "Enter a valid name",
                            options: {
                                type: "string",
                                placeholder: "Gaff Name"
                            },
                        }]],
                        submitString: "Create",
                        onSubmit: handleGaffCreationFormSubmit
                    })
                }
                show={[showGaffCreationForm, setShowGaffCreationForm]}
            />
        )
    }

    function gaffConfirm() {
        return (
            <ModalTemplate
                title="Set Mechanic To Gaff"
                body={`Confirm setting mechanic to gaff: ${selectedGaffData.name}`}
                confirmButtonString="Confirm"
                confirmType="success"
                callback={handleGaffConfirmFormSubmit}
                show={[showGaffConfirmForm, setShowGaffConfirmForm]}
            />
        )
    }

    function deleteGaffConfirm() {
        return (
            <ModalTemplate
                title="Delete Selected Gaff"
                body={`Are you sure you wish to delete : ${selectedGaffData.name}?`}
                confirmButtonString="Delete"
                confirmType="error"
                show={[deleteGaffConfirmForm, setDeleteGaffConfirmForm]}
                callback={handleDeleteGaffSubmit}
            />
        )
    }

    async function handleDeleteGaffSubmit() {
        let success = false;
        let message = "";
        await gaffController.deleteGaffData(selectedGaffData._id)
            .then((data: any) => {
                success = true;
                message = data.responseData.message;
            }).catch((data: any) => {
                success = false;
                message = data.responseData.message;
            }).finally(() => {
                gaffController.sendToastMessage({
                    body: message,
                    success: success
                });
                setDeleteGaffConfirmForm(false);
                setAnchorEl(null);
                setReady(false);
            });
    }

    async function handleGaffCreationFormSubmit(data: any) {
        let success = false;
        let message = "";
        await gaffController.createGaffData({
            "gameID": selectedGameID,
            "name": data[0].data,
        }).then((data: any) => {
            success = true;
            message = data.responseData.message;
        }).catch((data: any) => {
            success = true;
            message = data.responseData.message;
        }).finally(() => {
            gaffController.sendToastMessage({
                body: message,
                success: success
            });
            setShowGaffCreationForm(false);
            setReady(false);
        })
    }

    async function handleGaffConfirmFormSubmit() {
        let success = false;
        let message = "";
        await gaffController.updateSelectedGaff({
            "gaffID": selectedGaffData._id,
            "scenarioID": selectedMechanicID,
        }).then((data: any) => {
            success = true;
            message = data.responseData.message;
        }).catch((data: any) => {
            success = true;
            message = data.responseData.message;
        }).finally(() => {
            gaffController.sendToastMessage({
                body: message,
                success: success
            });
            setShowGaffConfirmForm(false);
            setMechanicsListOpen(false);
            setReady(false);
        })
    }

    function selectedGameDataElement(): JSX.Element | undefined | null {
        const button = (
            <React.Fragment>
                <Button onClick={() => { setShowGaffConfirmForm(true) }} variant="outlined" size="large" color="success" type="submit"><FontAwesomeIcon icon={["fas", "note-sticky"]} />&nbsp;Set Gaff</Button>
            </React.Fragment>
        )

        if (selectedGameData) {
            return (
                <Card sx={{
                    minWidth: 275,
                    p: 2,
                    margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}>
                    <CardContent>
                        <Typography variant="h5" paddingTop={5}> Summary </Typography>
                        Selected Game: <strong>{selectedGameTitle}</strong>< br />
                        Selected Gaff: <strong>{selectedGaffData.name}</strong>< br />
                        Selected Mechanic: <strong>{selectedMechanicID}</strong>
                    </CardContent>
                    <CardActions>
                        {mechanicsListOpen ? button : null}
                    </CardActions>
                </Card>
            )
        }
    }

    function createNewGaffButton(): JSX.Element {
        return (
            <Button onClick={() => { setShowGaffCreationForm(true) }} variant="outlined" size="large" color="success" type="submit"><FontAwesomeIcon icon={["fas", "note-sticky"]} />&nbsp;Create New Gaff</Button>
        )
    }

    function body(): JSX.Element {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Container className='overflow-hidden'>
                        {gamesListSeclection()}
                        <Grid container paddingBottom={3}>
                            {gaffListData.length > 0 ? gaffList() : null}
                            {(mechanicsListOpen && scenarios.length > 0) ? gaffMechanicList() : null}
                        </Grid>
                        {gaffCreationForm()}
                        {gaffConfirm()}
                        {deleteGaffConfirm()}
                        {selectedGameID !== 0 ? createNewGaffButton() : null}
                        {selectedMechanicID !== null ? selectedGameDataElement() : null}
                    </Container>
                </Grid>
            </Container>
        )
    }

    return gaffController.renderLayout(null, body(), {
        title: "Gaff Settings",
        helpString: (<React.Fragment>The Gaffs below are setup to be used when the ‘G’ button is pressed from within a game. These are intended to be a small selection of pre-determined forces that are easily accessible for demo and testing purposes. \n\n Select a game from the list then either click ‘Create New Gaff’ or select one of the pre-existing ones (if they already exist) by clicking the arrow beside it. To the right, you will see the forces in the ‘Mechanics List’. Select the ‘down’ arrow on one of the scenarios in the ‘Mechanics List’, and you will see them in the ‘Summary’ box below. Finally, click ‘set gaff’ on the ‘Summary’ box to set up a gaff for the game. You will now see a star beside it in the ‘Mechanics List’.</React.Fragment>)
    })

}