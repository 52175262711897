import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from "@mui/material";
import ConfigController from "./Config/controller/ConfigController";
import { contextEntry } from "./IComponent";
import { appContext } from "./root/MainContainer";
import { useLocation} from "react-router-dom";
import { IEmptyResult, IRequestQuery, IResponse } from "./root/data/MainContainerData";

export interface requestOptions {
    method: string,
    cookiefy?: string,
    query?: IRequestQuery[],
    url?: string,
    params?: string,
    full?: boolean,
    files?: boolean
    resolveCallback?: any,
    rejectCallback?: any,
    bypassCheck?: boolean
}

export interface pageOptions {
    title: string
    helpString: JSX.Element
}


export default class Controller {

    private location = useLocation();

    public route?: string;
    private readonly url: string = ConfigController.getConfigData().endpoint
    static readonly dev: boolean = window.location.hostname === "localhost" ? true : false;
    public helpTextString: string = "HELP TEXT NOT SET!";
    public subpageData: contextEntry[] = [];
    public alertBox: any;

    public contentReady: any;
    public setContentReady: any;
    public sendToastMessage: any;

    constructor() {

        this.sendToastMessage = useContext(appContext);

        [this.contentReady, this.setContentReady] = useState(false);

        useEffect(() => {
            if (!this.contentReady) {
                this.setContentReady(true);
            }
        }, [this.contentReady]);
    }

    public getURL() {
        return this.url;
    }
    public menu() {};
    public content() {};

    public helpText(override?: JSX.Element): ReactNode {
        return (
            <Accordion>
                <AccordionSummary><FontAwesomeIcon icon={["fas", "circle-question"]} beat size="xl" />&nbsp;&nbsp;Whats this?</AccordionSummary>
                <AccordionDetails>
                {override ? override : null}
                </AccordionDetails>
            </Accordion>
        )
    }

    public static getCookies(key: string): string {
        let cookies = new Map();
        try {
            document.cookie.split(" ").forEach((e) => {
                let parsed = e.split("=")
                cookies.set(parsed[0], parsed[1].split(';')[0]);
            });
            return cookies.get(key);
        } catch (err) {
            return "";
        }
    }

    public renderLayout(headerContent: JSX.Element | null, bodyContent: ReactNode, options?: pageOptions): JSX.Element {
        if(this.location.pathname !== "/") {
            return (
                <Container>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                        <Grid item xs={12}>
                            <h1>{options?.title}</h1>
                            {headerContent}
                        </Grid>
                        <Grid item xs={12}>
                            {this.helpText(options?.helpString)}
                        </Grid>
                        <Grid item xs={12}>
                            {bodyContent}
                        </Grid>
                    </Grid>
                </Container>
            )
        } else {
            return (
                <Container>
                    {bodyContent}
                </Container>
            )
        }
    }


    public get successMessage() {
        return this.alertBox.display.successMessage[0];
    }

    public set successMessage(value: string) {
        this.alertBox.display.successMessage[1](value);
        this.setContentReady(false);
    }

    public get errorMessage() {
        return this.alertBox.display.errorMessage[0];
    }

    public set errorMessage(value: string) {
        this.alertBox.display.errorMessage[1](value);
        this.setContentReady(false);
    }

    public isEmptyResult(value: IEmptyResult): value is IEmptyResult {
        if(value.data === null && typeof value.message === "string") {
            return true
        } else {
            return false;
        }
    }

    public sendRequest(options: requestOptions, body?: {}): Promise<IResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const resolveResponse = (response: any): any => {
                    if (response.code === "error") {
                        throw new Error(response.responseData.message);
                    }
                    if (options.cookiefy) {
                        Object.keys(response.responseData.data).forEach((e) => {
                            document.cookie = `${e}=${response.responseData.data[e]}`;
                        });
                    }
                    if (options.full === undefined ? true : options.full) {
                        resolve(response);
                    } else {
                        resolve(response.responseData.data);
                    }
                    console.log(response.responseData.data);

                    if (options.resolveCallback) {
                        options.resolveCallback();
                    }
                }

                function checkResponse(response: any): any {
                    if (response.ok && !options?.bypassCheck) {
                        return response.json();
                    }
                    throw response
                }

                let urlString = options.url !== undefined ? `${options.url}` : this.route;
                let paramsString = options.params !== undefined ? `/${options.params}` : "";
                let queryString = options.query !== undefined ? options.query.map((e: IRequestQuery, i: number) => { let nextQuery = i > 0 ? `&` : ""; return `${nextQuery}?${e.name}=${e.value}` }) : "";
                let requestString = this.getURL() + urlString + paramsString + queryString;

                if (options.method === "GET") {
                    await fetch(requestString, {
                        method: "GET",
                    })
                        .then(checkResponse)
                        .then(resolveResponse)
                        .catch((error: any) => {
                            this.sendToastMessage({
                                body: error.error ? `${error.status} : ${error.url}` : `${error}`,
                                success: false
                            });
                        })
                } else {
                    options.files !== true ? await fetch(requestString, {
                        method: options.method,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    })
                        .then(checkResponse)
                        .then(resolveResponse)
                        .catch((error: any) => {
                            this.sendToastMessage({
                                body: error.error ? `${error.status} : ${error.url}` : `${error}`,
                                success: false
                            });
                        }) : await fetch(requestString, {
                            method: options.method,
                            body: body as FormData,
                        })
                            .then(checkResponse)
                            .then(resolveResponse)
                            .catch((error: any) => {
                                this.sendToastMessage({
                                    body: error.error ? `${error.status} : ${error.url}` : `${error}`,
                                    success: false
                                });
                            })
                }
            } catch (error: any) {
                console.log(error.message);
                reject(error.message);

                if (options.rejectCallback) {
                    options.rejectCallback();
                }
            };
        });
    }

} 
