import { Box, Chip, Grid, IconButton, Pagination, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from "@mui/material";
import ServerLogsController from "./controller/ServerlogsController";
import React, { useEffect, useState } from "react";
import { ELogLevel, ILog } from "./data/ServerLogsData";
import SearchBar from "../instances/searchBar";
import { Cached, Info } from "@mui/icons-material";
import { Item } from "../root/MainContainer";

export default function ServerLogs() {
    const controller = new ServerLogsController();
    const [tabIndex, setTabIndex] = useState(ELogLevel.message);
    const [ready, setReady] = useState(false);
    const [data, setData] = useState([]);
    const [queryedResults, setQueryedResults] = useState([]);
    const [queryedTotal, setQueryedTotal] = useState(0);
    const [onPage, setOnPage] = useState(1);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        getData();
        if (!ready) {
            setReady(true);
        }
    }, [ready,tabIndex,skip]);

    function getData() {
        let payload: ILog;
        payload = {
            level: tabIndex,
            skip : skip
        }

        controller.getData(payload).then((newData: unknown | { results: [], total: number }) => {
            let entries: { results: [], total: number } = (newData as { results: [], total: number });
            setData([...entries.results]);
            setQueryedResults([...entries.results]);
            setQueryedTotal(entries.total)
        });
    }


    function SelectionTab(): JSX.Element {
        const onChangeTab = (event: React.SyntheticEvent, newValue: ELogLevel) => {
            setTabIndex(newValue);
        }

        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item><Tabs
                        value={tabIndex}
                        onChange={onChangeTab}
                        aria-label="server logs tabs"
                    >
                        <Tab value={ELogLevel.message} label="Message" />
                        <Tab value={ELogLevel.info} label="Info" />
                        <Tab value={ELogLevel.warning} label="Warning" />
                        <Tab value={ELogLevel.error} label="Error" />
                    </Tabs></Grid>
                    <Grid item alignSelf="right"><IconButton
                        aria-label="more"
                        aria-haspopup="true"
                        onClick={() => {
                            getData()
                        }}
                    >
                        <Cached />

                    </IconButton>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function PaginationBar() {
        let show = Math.floor(queryedTotal / 10) > 0;

        const onChange = (event: React.ChangeEvent<unknown>, page: number) => {
            setOnPage(page);
            setSkip(page !== 0 ? ((page - 1) * 10) : 0);
        }

        return show ? <Pagination onChange={onChange} page={onPage} count={Math.floor(queryedTotal / 10)} variant="outlined" /> : null
    }

    function ServerMessages() {
        let messageCollection = queryedResults;

        let message = (e: ILog, i: number) => {
            return (
                <TableRow key={`log_message_${i}`}>
                    <TableCell>
                        {e.user === "SYSTEM" ? (<Chip label="SYSTEM" color="warning" />) : (<Chip label={e.user} color="success" />)}
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }}>{e.message}</Typography>
                    </TableCell>
                    <TableCell>
                        {e.timestamp !== undefined ? e.timestamp.toString() : null}
                    </TableCell>
                </TableRow>
            )
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} >
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>TimeStamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messageCollection.map((e: ILog, i: number) => { return message(e, i) })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function body(): JSX.Element {
        return (
            <Box>
                <SearchBar dataSource={data} output={setQueryedResults} fields={["user"]} limit={3} />
                <SelectionTab />
                <Grid container>
                    <Grid item xs={12}>
                        <ServerMessages />
                    </Grid>
                    <Grid item paddingTop={3}>
                        <PaginationBar />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Server Logs",
        helpString: (<React.Fragment></React.Fragment>)
    })
}