import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import Permissions from "../Permissions";

export default class PermissionsController extends Controller {
    constructor() {
        super();
        this.route = ''
    }

    setConfigData(data : any) {
        localStorage.setItem("endpoint",data.endpoint);
    }

    menu() : menuEntry {
        return {
            key: "permissionsMenu",
            name: "User Permissions",
            color : "#ff5741",
            style : "settingsGroupMenu",
            link: "/permissions",
        }
    }

    content() : contextEntry {
        return {
            key:"permissionsContext",
            title: "User Permissions",
            content: <Permissions/>,
            path : "/permissions"
        }
    }
}