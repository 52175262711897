import { Box, Button, FilledInput, FormControl, FormControlLabel, Grid, InputLabel, Switch, Typography, styled } from "@mui/material";
import { useForm } from "react-hook-form"
import { CloudUpload, SystemUpdateAlt } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Controller from "../../Controller";

type formData = {
    gameID: string,
    gameName: string,
    mechanic: File | null,
    table: File | null,
    struct: File | null,
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

export default function ImporterForm(props: {
    confirm: React.Dispatch<React.SetStateAction<boolean>>
    data: {
        get: FormData,
        set: React.Dispatch<React.SetStateAction<FormData>>
    }
    controller : Controller
}) {
    const {
        register,
        watch,
        setError,
        setValue,
        getValues,
        handleSubmit,
        formState,
        clearErrors
    } = useForm<formData>({
        defaultValues: {
            gameID: "",
            gameName: "",
            mechanic: null,
            table: null,
            struct: null,
        }
    });

    const [useBlankMechanic, setUseBlankMechanic] = useState<boolean>(false);

    useEffect(() => {
        if (formState.isValidating) {
            ["mechanic", "table", "struct"].forEach((e) => checkForFileErrors(e))
        }
        if(formState.isSubmitting && !formState.isValid) {
            props.controller.sendToastMessage({
                success: false,
                body: "Please Check form for Errors",
            })
        }
    }, [formState]);

    const watchMechanic = watch("mechanic");
    const watchTable = watch("table");
    const watchStruct = watch("struct");

    const onSubmit = (data: any) => {
        let correctFiles = ["mechanic", "table", "struct"].every((e) => checkForFileErrors(e));
        if (correctFiles) {
            const formData = new FormData();
            getValues();
            for (const [key, value] of Object.entries(data)) {
                if (typeof value === "string") {
                    formData.append(key, value);
                } else {
                    formData.append(key, data[key]);
                }
            }
            props.data.set(formData);
            props.confirm(true);
        }
    }
    const addFile = (data: any): File => {
        let file: File = data.target.files.item(0);
        return file;
    }


    const checkForFileErrors = (file: string): boolean => {
        if(file === "mechanic" && useBlankMechanic) {
            return true;
        } 
        type acceptedName = "root" | `root.${string}` | "table" | "gameID" | "gameName" | "mechanic" | "struct";
        for (let [key, values] of Object.entries(getValues())) {
            if (key === file) {
                if (values === null) {
                    setError((file as acceptedName), {
                        type: "manual",
                        message: "File is not set",
                    })
                    return false;
                } else if ((values as any)["type"] !== "text/csv") {
                    setError((file as acceptedName), {
                        type: "manual",
                        message: "File is not CSV",
                    })
                    return false;
                } else {
                    clearErrors((file as acceptedName));
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box paddingBottom={3} sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        container
                    >
                        <Grid item xs={12} marginBottom={2}>
                            <FormControl fullWidth variant="filled" >
                                <InputLabel htmlFor="gameID">GameID</InputLabel>
                                <FilledInput
                                    error={formState.errors.gameID !== undefined}
                                    type="number"
                                    id="gameID"
                                    defaultValue={getValues().gameID}
                                    {...register(`gameID`, {
                                        required: "Field cannot be empty and must contain at least 4 digits",
                                        minLength: {
                                            value: 4,
                                            message: "Field must contain at lest 4 digits"
                                        },
                                        validate: (value) => {
                                            return value.length > 3;
                                        }
                                    })} />
                                <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.gameID?.message}</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} marginBottom={2}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel htmlFor="gameName">GameName</InputLabel>
                                <FilledInput
                                    error={formState.errors.gameName !== undefined}
                                    id="gameName" defaultValue={getValues().gameName}
                                    {...register(`gameName`, {
                                        required: "Field cannot be empty"
                                    })} />
                                <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.gameName?.message}</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                container>
                                <Grid item xs={2}>
                                    <Typography gutterBottom>Mechanic</Typography>
                                    <Button disabled={useBlankMechanic} {...register(`mechanic`)} component="label" variant="contained" startIcon={<CloudUpload />}>
                                        Upload file
                                        <VisuallyHiddenInput id={"mechanic"}
                                            onChange={(data: any) => { setValue("mechanic", addFile(data)); checkForFileErrors("mechanic") }}
                                            type="file" />
                                    </Button>
                                    <Typography marginTop={0.5} marginBottom={0} variant="caption" display="block" gutterBottom>{watchMechanic !== null ? watchMechanic.name : "Nothing Selected"}</Typography>
                                    <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.mechanic?.message?.toString()}</Typography>
                                </Grid>
                                <Grid item >
                                    <FormControlLabel control={<Switch onChange={() => { setUseBlankMechanic(!useBlankMechanic); useBlankMechanic ? clearErrors("mechanic") : null }} checked={useBlankMechanic} />} label="Use Blank Mechanic" />
                                    {useBlankMechanic === true ? <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>A Blank Scenario will be set for this Mechanic, You will need to edit this in the Games List Mechanic Editor!</Typography> : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>Prize Table</Typography>
                            <Button {...register(`table`)} component="label" variant="contained" startIcon={<CloudUpload />}>
                                Upload file
                                <VisuallyHiddenInput id={"table"}
                                    onChange={(data: any) => { setValue("table", addFile(data)); checkForFileErrors("table") }}
                                    type="file" />
                            </Button>
                            <Typography marginTop={0.5} marginBottom={0} variant="caption" display="block" gutterBottom>{watchTable !== null ? watchTable.name : "Nothing Selected"}</Typography>
                            <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.table?.message?.toString()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>Prize Structure</Typography>
                            <Button {...register(`struct`)} component="label" variant="contained" startIcon={<CloudUpload />}>
                                Upload file
                                <VisuallyHiddenInput id={"struct"}
                                    onChange={(data: any) => { setValue("struct", addFile(data)); checkForFileErrors("struct") }}
                                    type="file" />
                            </Button>
                            <Typography marginTop={0.5} marginBottom={0} variant="caption" display="block" gutterBottom>{watchStruct !== null ? watchStruct.name : "Nothing Selected"}</Typography>
                            <Typography sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.struct?.message?.toString()}</Typography>
                        </Grid>
                    </Grid >
                </Box >
                <Button type="submit" variant="contained" color="primary" startIcon={<SystemUpdateAlt />}>Submit Data</Button>
            </form>
        </React.Fragment >
    )
}