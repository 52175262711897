import React, { useContext } from "react";
import SplashController from "./controller/SplashController";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Item, appContext } from "../root/MainContainer";
import Controller from "../Controller";

export default function Splash() {
    let controller = new SplashController();
    const sendToastMessage = useContext(appContext);

    function showWelcomeMessage() {
        let time = new Date().getHours();
        let message = "";

        if (time < 12) {
            message = `Good Morning ${Controller.getCookies("user")}!`
        } else if (time < 16) {
            message = `Good Afternoon ${Controller.getCookies("user")}!`
        } else {
            message = `Good Evening ${Controller.getCookies("user")}!`
        }

        return (
            <Grid item paddingBottom={2}>
                <Typography textAlign={"center"} variant="h3">{message}</Typography>
            </Grid>
        )
    }

    function showToken() {

        const handleCopyAction = ()=>{
            navigator.clipboard.writeText(Controller.getCookies("sessionToken"));
            sendToastMessage({
                body: `Token : ${Controller.getCookies("sessionToken")} copied to clipboard! `,
                success: true
            });
            close();
        }

        return (
            <Grid item>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="caption">Token</Typography>
                        <Typography variant="h5">{Controller.getCookies("sessionToken")}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleCopyAction}>Copy</Button>
                    </Grid>
                </Grid>

            </Grid>
        )
    }

    function body() {
        return (
            <React.Fragment>
                <Grid container
                    rowSpacing={1}
                    columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {showWelcomeMessage()}
                        {showToken()}
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "",
        helpString: (<React.Fragment></React.Fragment>)
    })

}