import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import JackpotMessages from "../JackpotMessages";
import { IResponse } from "../../root/data/MainContainerData";
import { IJackpotMessage } from "../data/JackpotMessageData";

export default class JackpotMessagesController extends Controller {
    
    constructor() {
        super();
        this.route = 'admin/jackpotMessages'
    }

    public getData(groupCode : string) : Promise<IJackpotMessage[] | null> {
        return new Promise<IJackpotMessage[] | null>(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", params:groupCode }).then((data : IResponse) => {
                const acceptedData = (check:IJackpotMessage[]) : check is IJackpotMessage[] => {
                    return data.responseData.data;
                }
                return resolve(acceptedData(data.responseData.data) ? data.responseData.data as IJackpotMessage[] : null);
            }).catch((err) => {
                return reject(err as string);
            })
        });
    }

    menu() : menuEntry {
        return {
            key: "jackpotMessagesMenu",
            name: "Jackpot Messages",
            color : "#ff5741",
            style : "jackpot",
            link: "/jackpotMessages",
        }
    }

    content() : contextEntry {
        return {
            key:"jackpotMessagesContext",
            title: "Jackpot Messages",
            content: <JackpotMessages />,
            path : "/jackpotMessages"
        }
    }
}