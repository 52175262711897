import React, { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Slide, Snackbar } from "@mui/material";
import { red } from "@mui/material/colors";

export interface dataStatusMessageOptions {
    id?: string,
    success: boolean,
    body: string,
    burn?: () => void
}


export default function DataStatusMessage(props: dataStatusMessageOptions) {
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;

    const [show, setShow] = useState(true);

    const action = (
        <React.Fragment>
            <small>{dateTime}</small>
        </React.Fragment>
    )

    return (
        <Snackbar
            open={show}
            autoHideDuration={5000}
            TransitionComponent={Slide}
            onClose={() => { setShow(false); }}
            message={props.body}
        >
            <Alert
                severity={props.success ? "success" : "error"}
                variant="filled"
                sx={{ width: '100%' }}
            >
                 <AlertTitle>{props.success ? "Success" : "Error"}</AlertTitle>
                {props.body}
            </Alert>
        </Snackbar>
    )
}