import React, { useContext, useEffect, useState } from "react";
import JackpotGroupController from "./controller/JackpotGroupController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRight, VolumeDown, VolumeUp } from "@mui/icons-material";
import { Button, Box, Typography, Grid, Divider, ListItem, ListItemIcon, List, Container, FormControl, TextField, Slider, Stack, ToggleButton, Card } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { IformData, formGroupOptions, formTemplate } from "../instances/form";
import { ModalTemplate } from "../instances/modal";
import SearchBar from "../instances/searchBar";
import { appContext } from "../root/MainContainer";
import { IHeatIndex, IJackpotGroup, IMeterBands } from "./data/JackpotGroupData";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface IProps {
    controller: JackpotGroupController
}

export default function JackpotGroups(props: IProps) {
    let controller = new JackpotGroupController();
    let [ready, setReady] = useState(false);
    let [showConfirmModal, setShowConfirmModal] = useState(false);

    const sendToastMessage = useContext(appContext);

    let jackpotGroups: any;
    let setJackpotGroups: any;
    [jackpotGroups, setJackpotGroups] = useState<[]>([]);

    let filteredGlobalsData: any;
    let setfilteredGlobalsData: any;
    [filteredGlobalsData, setfilteredGlobalsData] = useState<[]>([]);

    let selectedData: any | null;
    let setSelectedData: any;
    [selectedData, setSelectedData] = useState(null);

    let groupData: any | null;
    let setGroupData: any;
    [groupData, setGroupData] = useState([]);

    let [formData, setFormData] = useState<IformData | []>([]);

    let showDeleteModal: any | null;
    let setShowDeleteModal: any;
    [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (!ready) {
            getData();
            setReady(true);
        }
    },
        [ready])

    async function getData() {
        await controller.getData().then((newData) => {
            setJackpotGroups(newData != null ? newData : [])
            setfilteredGlobalsData(newData != null ? newData : []);
        });
    }

    async function filteredData(updatedData: any) {
        setfilteredGlobalsData(updatedData);
    }

    function confirmModel() {
        return ModalTemplate({
            title: "Create New Jackpot Group",
            body: "Enter Name For Jackpot Group",
            confirmButtonString: "Add",
            confirmType: "success",
            confirmIcon: "add",
            form: formTemplate({
                formGroup: [[{
                    label: "Jackpot Group Name",
                    id: "name",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Enter a valid Jackpot Group name",
                    options: {
                        type: "string",
                        placeholder: "Please Enter Name"
                    },
                }],
                [{
                    label: "Jackpot Group Code",
                    id: "id",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Enter a valid Jackpot Group Code",
                    options: {
                        type: "string",
                        placeholder: "Please Enter Name"
                    },
                }]],
                onSubmit: (data : any)=>{
                    controller.submitCreateNewGroup(data,{
                        setReady: setReady,
                        setShowConfirmModal : setShowConfirmModal
                    })
                },
                submitString: "Submit",
            }),
            show: [showConfirmModal, setShowConfirmModal]
        })
    }

    async function updateGroup(callbackData: any) {
        await controller.updateGroup(
            selectedData.title,
            callbackData.map((e: any) => { return e.data })
        )
        setReady(false);
        sendToastMessage({
            body: `${selectedData.title} Updated!`,
            success: true
        });
    }

    function groupProps(key: any, value: any) {

        return [{
            label: key,
            id: key,
            feedbackSuccess: "This name looks ok!",
            feedbackInvalid: "Enter a valid Global Data Set name",
            options: {
                type: "string",
                placeholder: value !== undefined ? value : `Enter new value...`,
            },
            default: value !== undefined ? value : "",
        }]

    }

    function addNewRow(data: string | undefined) {
        setSelectedData(...selectedData);
    }

    function breakdownFormData(data: any, formdata: any) {
        for (let [key, value] of Object.entries(data)) {
            if (key[0] !== "_") {
                if (typeof value === "object") {
                    breakdownFormData(value, formdata);
                } else {
                    formdata.push(groupProps(key, value));
                }
            }
        }
    }

    function JackpotForm(props: { data: IJackpotGroup }): JSX.Element {

        let [minMessageValue, setMinMessageValue] = useState(props.data.minimumMessageValue)
        let [showCurrentCycle, setShowCurrentCycle] = useState(false);
        let [showCurrentCycleHeatIndex, setShowCurrentCycleHeatIndex] = useState(false);
        let [showFutureCycle, setShowFutureCycle] = useState(false);
        let [showFutureCycleHeatIndex, setShowFutureCycleHeatIndex] = useState(false);
        let [showBands, setShowBands] = useState(false);

        let currentCycleHeatIndex = () => {
            let indexes = props.data.currentCycle.heatIndex.index
            return (
                <React.Fragment>
                    {indexes.map((e : any, i : number) => {
                        return (
                            <React.Fragment key={`currentCycleHeatIndexCollection_${i}`}>
                                <Grid item xs={12} key={`currentCycle_heatIndex_index1`}>
                                    <TextField fullWidth id={`currentCycle_heatIndex_index1`} label="index 1" variant="outlined" defaultValue={e.index1} />
                                </Grid>
                                <Grid item xs={12} key={`currentCycle_heatIndex_index2`}>
                                    <TextField fullWidth id={`currentCycle_heatIndex_index2`} label="index 2" variant="outlined" defaultValue={e.index2} />
                                </Grid>
                                <Grid item xs={12} key={`currentCycle_heatIndex_index3`}>
                                    <TextField fullWidth id={`currentCycle_heatIndex_index3`} label="index 3" variant="outlined" defaultValue={e.index3} />
                                </Grid>
                            </ React.Fragment>
                        )
                    })}
                </React.Fragment >
            )
        }

        let futureCycleHeatIndex = () => {
            let indexes = props.data.futureCycle.heatIndex.index
            return (
                <React.Fragment>
                    {indexes.map((e : any, i : number) => {
                        return (
                            <React.Fragment key={`futureCycleHeatIndexCollection_${i}`}>
                                <Grid item xs={12} key={`futureCycle_heatIndex_index1`}>
                                    <TextField fullWidth id={`futureCycle_heatIndex_index1`} label="index 1" variant="outlined" defaultValue={e.index1} />
                                </Grid>
                                <Grid item xs={12} key={`futureCycle_heatIndex_index2`}>
                                    <TextField fullWidth id={`futureCycle_heatIndex_index2`} label="index 2" variant="outlined" defaultValue={e.index2} />
                                </Grid>
                                <Grid item xs={12} key={`futureCycle_heatIndex_index3`}>
                                    <TextField fullWidth id={`futureCycle_heatIndex_index3`} label="index 3" variant="outlined" defaultValue={e.index3} />
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </React.Fragment>
            )
        }

        let meterband = (bands: IMeterBands, index: number) => {
            return (
                <React.Fragment key={`meterband_${index}`}>
                    <Grid item xs={12}>
                        <TextField fullWidth id={`meterband_${index}_bandID`} label="bandID" variant="outlined" defaultValue={bands.bandid} />
                    </Grid>
                    {bands.meterBand.jackpot.map((e: { id: string, level: number }, i: number) => {
                        return (
                            <React.Fragment key={`meterband_jackpot_${i}`}>
                                <Grid item xs={12} key={`jackpot_${i}_id`}>
                                    <TextField fullWidth id={`jackpot_${i}_id`} label={`Jackpot ${i} ID`} variant="outlined" defaultValue={e.id} />
                                </Grid>
                                <Grid item xs={12} key={`jackpot_${i}_level`}>
                                    <TextField fullWidth id={`jackpot_${i}_level`} label={`Jackpot ${i} Level`} variant="outlined" defaultValue={e.level} />
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </React.Fragment>
            )
        }


        let currentCycle = () => {
            return (

                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="center" alignItems="center" padding={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth id="currentCycle_contribution_band" label="Current Cycle Contribution Band" variant="outlined" defaultValue={props.data.currentCycle.contribution.band} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth id="currentCycle_total" label="Current Cycle Total" variant="outlined" defaultValue={props.data.currentCycle.total} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button size="small" onClick={(data: any) => { setShowCurrentCycleHeatIndex(!showCurrentCycleHeatIndex) }}>
                                <Divider>
                                    Current Cycle Heat Index Total
                                </Divider>
                                {showCurrentCycleHeatIndex === true ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </Button>
                        </Stack>
                    </Grid>
                    {showCurrentCycleHeatIndex === true ? currentCycleHeatIndex() : null}
                </Grid>
            )
        }

        let futureCycle = () => {
            return (
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="center" alignItems="center" padding={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth id="currentCycle_contribution_band" label="Current Cycle Contribution Band" variant="outlined" defaultValue={props.data.currentCycle.contribution.band} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth id="currentCycle_total" label="Current Cycle Total" variant="outlined" defaultValue={props.data.currentCycle.total} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button size="small" onClick={(data: any) => { setShowFutureCycleHeatIndex(!showFutureCycleHeatIndex) }}>
                                <Divider>
                                    Current Cycle Heat Index Total
                                </Divider>
                                {showFutureCycleHeatIndex === true ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                            </Button>
                        </Stack>
                    </Grid>
                    {showFutureCycleHeatIndex === true ? futureCycleHeatIndex() : null}
                </Grid>
            )
        }

        let bands = () => {
            return (
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="center" alignItems="center" padding={2}>
                    {props.data.bands.map((e: IMeterBands, i: number) => { return meterband(e, i) })}
                </Grid>
            )
        }

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card variant="outlined">
                    <Grid container direction="column" justifyContent="center" alignItems="center" padding={2}>
                        <Grid item xs={12}>
                            <Divider>
                                <Typography variant="h5" align="center" paddingTop={2} paddingBottom={2}>{props.data.groupName}</Typography>
                            </Divider>
                        </Grid>
                    </Grid>
                    <FormControl fullWidth>
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 12, sm: 12, md: 12 }} justifyContent="center" alignItems="center" padding={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth id="groupName" label="Group Name" variant="outlined" defaultValue={props.data.groupName} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="groupCode" label="Group Code" variant="outlined" defaultValue={props.data.groupCode} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="groupTemplate" label="Group Template" variant="outlined" defaultValue={props.data.groupTemplate} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
                                    <Typography variant="body2">Minimum Message Value</Typography>
                                    <Slider aria-label="props.data.minimumMessageValue" value={minMessageValue} onChange={(data: any) => { data.target !== null ? setMinMessageValue(data.target.value) : 0 }} />
                                    <Typography variant="button">{minMessageValue}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button size="small" onClick={(data: any) => { setShowCurrentCycle(!showCurrentCycle) }}>
                                        <Divider>
                                            Current Cycle
                                        </Divider>
                                        {showCurrentCycle === true ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    {showCurrentCycle === true ? currentCycle() : null}
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button size="small" onClick={(data: any) => { setShowFutureCycle(!showFutureCycle) }}>
                                        <Divider>
                                            Future Cycle
                                        </Divider>
                                        {showFutureCycle === true ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    {showFutureCycle === true ? futureCycle() : null}
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Button size="small" onClick={(data: any) => { setShowBands(!showBands) }}>
                                        <Divider>
                                            Bands
                                        </Divider>
                                        {showBands === true ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    {showBands === true ? bands() : null}
                                </Card>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Card>
            </Box>
        )
    }

    function deleteKeyModal() {
        let keys = groupData.map((e: string) => {
            return <ListItem key={`${e}_delete_list`}>
                <ListItemIcon>
                    <ArrowRight />
                </ListItemIcon> {e} </ListItem>
        })
        let keyList = <List>{keys}</List>

        return <ModalTemplate title={"Delete Selected Keys?"} confirmButtonString={"Delete"} confirmType={"error"} callback={() => { controller.deleteKey(groupData,
            {
                setReady:setReady,
                setShowDeleteModal:setShowDeleteModal
            })
        }} show={[showDeleteModal, setShowDeleteModal]} body={keyList} />
    }

    function deleteKeyButton(): JSX.Element {
        return (
            <Button color="error" variant="outlined" onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp; Delete Selected</Button>
        )
    }

    function keyGroup() {
        if(filteredData.length === 0) {
            return null;
        }

        return (
            <Box>
                <div style={{ height: 632, width: '100%' }}>
                    <DataGrid
                        rows={filteredGlobalsData}
                        getRowId={(row: IJackpotGroup) => row.groupCode}
                        columns={[
                            { field: 'groupCode', headerName: 'Group Code' },
                            { field: 'groupName', headerName: 'Group Name' },
                            { field: 'groupTemplate', headerName: 'Group Template' }
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        onRowClick={(params: GridRowParams) => {
                            setSelectedData(null);

                            let data = Object.keys(params.row).map((e: any) => {
                                return e;
                            });

                            setTimeout(() => {
                                setFormData(data as []);
                                setSelectedData(params.row);
                            }, 100)

                        }}
                        onRowSelectionModelChange={(params: any) => {
                            let tmp: IJackpotGroup[] = params.map((e: IJackpotGroup) => { return e });
                            setGroupData(tmp);

                        }}
                        isRowSelectable={(params: GridRowParams) => !params.row.lock}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </div>
            </Box>
        );
    }

    function body() {
        return (
            <Container>
                <SearchBar dataSource={jackpotGroups} output={filteredData} fields={["groupCode", "groupName", "groupTemplate"]} limit={3} />
                <Grid container paddingTop={5} spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={{
                            class: "dataContainer"
                        }}>
                            {keyGroup()}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {selectedData !== null ? (<JackpotForm data={selectedData} />) : null}
                    </Grid>
                </Grid>

                <Box paddingTop={5}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item>
                            <Button variant="outlined" color="success" onClick={() => { setShowConfirmModal(true) }}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp;Create New Jackpot Group</Button>
                        </Grid>
                        <Grid item>
                            {groupData.length > 0 ? deleteKeyButton() : null}
                        </Grid>
                        {deleteKeyModal()}
                        {confirmModel()}
                    </Grid>
                </Box>
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Jackpot Groups",
        helpString: (<React.Fragment></React.Fragment>)
    })
}