import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import Importer from "../Importer"

export enum importState {
    idle,
    error,
    complete
}

export default class ImporterController extends Controller {
    public importDataState = importState.idle;
    public errorStatus = "";
    constructor() {
        super();
        this.route = 'consume/csv';
        this.helpTextString = "This tool is form importing CSV data into the internal DB";
    }


    public async sendData(inputData : any) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "POST", files: true},
            inputData
            ).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    menu(): menuEntry {
        return {
            key: "importerMenu",
            name: "Create New Game",
            style: "dataGroupMenu",
            color: "#81acdd",
            link: "/importer",
        }
    }

    content(): contextEntry {
        return {
            key: "importerContext",
            title: "Create New Game",
            content: <Importer />,
            path: "/importer"
        }
    }
}