import { Component, ReactNode } from "react";
import ConfigController from "./controller/ConfigController";
import { Alert, Box, Button, Divider, FormControl, FormGroup, Input, TextField } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formTemplate } from "../instances/form";

export default function Config() {
    const controller: ConfigController = new ConfigController();
    const [validated, setValidated] = React.useState(false);


    function handleSubmit(data: any) {
        controller.setConfigData(data);
    }

    function serverSettings() {

        let configdata = ConfigController.getConfigData();

        return formTemplate({
            formGroup: [[{
                label: "Server Endpoint",
                id: "endpoint",
                feedbackSuccess: "This ID looks ok!",
                feedbackInvalid: "Example: https://localhost:3000/",
                options: {
                    type: "string",
                    placeholder: `${configdata.endpoint}`,
                },
                classes: `md="6"`,
                default: `${configdata.endpoint}`
            }]],
            onSubmit: handleSubmit,
            submitString: "Submit",
        })
    }

    function testSnackBar(type: "success" | "error") {
        controller.sendToastMessage({
            body: `This is a ${type} popup`,
            success: type === "success" ? true : false
        });
    }

    function genreateGlobals() {
        return (
            <Box sx={{ '& button': { m: 1 } }}>
                <Button onClick={() => { controller.generateGlobals() }} variant="outlined" size="large" color="primary" type="submit"><FontAwesomeIcon icon={["fas", "note-sticky"]} />&nbsp;Generate Default Globals</Button>
            </Box>
        )
    }

    function body() {
        return (
            <React.Fragment>
                {serverSettings()}
                < Divider />
                {genreateGlobals()}
                <Box sx={{ '& button': { m: 1 } }}>
                    <Button onClick={() => { testSnackBar("success") }} variant="outlined" size="large" color="primary" type="submit">Test Snack success</Button>
                </Box>
                <Box sx={{ '& button': { m: 1 } }}>
                    <Button onClick={() => { testSnackBar("error") }} variant="outlined" size="large" color="primary" type="submit">Test Snack error</Button>
                </Box>
            </React.Fragment>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Config",
        helpString: (<React.Fragment></React.Fragment>)
    });
}