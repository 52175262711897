import { response } from "express";
import Controller from "../../Controller";
import { contextEntry, menuEntry } from "../../IComponent";
import Gaff from "../Gaff";

export default class GaffController extends Controller {
    public tempPlayers: any = [];
    public permPlayers: any = [];
    public data: any = [];

    constructor() {
        super();
        this.route = "gaff";
    }

    public async getGameList() {
        return new Promise(async (res) => {
            this.tempPlayers = [];
            this.permPlayers = [];
            await fetch(this.getURL() + this.route, {
                method: "GET",
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response
                })
                .then(response => {
                    res(response.responseData.data as []);
                    console.log(response.responseData.data);
                })
                .catch(error => {
                    console.error(error);
                    res(null);
                })
        });
    }

    public getGameGaffData(gameID: number | null): Promise<unknown> {
        return new Promise(async (res) => {

            // const data : {} = {"gameID": gameID};

            await fetch(this.getURL() + this.route + `/find?gameID=${gameID}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response
                })
                .then(response => {
                    if (response.code === "success") {
                        res(response.responseData.data as []);
                        console.log(response.responseData.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                    res(error);
                })
        });
    }

    public createGaffData(inputData: any): Promise<unknown> {
        return new Promise(async (res) => {
            const data: {} = {
                "gameID": inputData.gameID,
                "name": inputData.name,
            };

            await fetch(this.getURL() + this.route + `/assign`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response
                })
                .then(response => {
                    res(response as []);
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                    res(error);
                })
        });
    }

    public updateSelectedGaff(inputData: any) : Promise<unknown>  {
        return new Promise(async (res) => {

            const data: {} = {
                "scenarioID": inputData.scenarioID,
            };

            await fetch(this.getURL() + this.route + `/assign/${inputData.gaffID}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response: any) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response
                })
                .then(response => {
                    res(response as []);
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                    res(error);
                })
        })
    }

    public deleteGaffData(id: string): Promise<unknown> {
        return new Promise(async (res) => {

            await fetch(this.getURL() + this.route + `/delete/${id}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                }
                throw response
            })
            .then(response => {
                res(response as []);
                console.log(response);
            })
            .catch(error => {
                console.error(error);
                res(error);
            })
        });
    }

    public parsePlayers(data: any) {
        let tempPlayers: any = [];
        let permPlayers: any = [];
        data.forEach((e: any) => {
            if (e.tempPlayer) {
                tempPlayers.push(e);
            } else {
                permPlayers.push(e);
            }
        });
        return {
            "tempPlayers": tempPlayers,
            "permPlayers": permPlayers
        }
    }

    menu(): menuEntry {
        return {
            key: "GaffMenu",
            name: "Gaff Data",
            color: "#ff5741",
            style: "settingsGroupMenu",
            link: "/gaff",
        }
    }

    content(): contextEntry {
        return {
            key: "gaffContext",
            title: "GaffData",
            content: <Gaff />,
            path: "/gaff"
        }
    }

}