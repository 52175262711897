import Controller from "../../Controller";
import { contextEntry, menuEntry } from "../../IComponent";
import GlobalTransactionHistory from "../GlobalTransactionHistory";

export default class GlobalTransactionHistoryController extends Controller {

    constructor() {
        super();
        this.route = "admin/transactions";

    }

    public getAllTransactions() : Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", full:false}).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }

    public getTransactions(id: string) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", params: id
            }).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }

    menu() : menuEntry {
        return {
            key: "globalTransactionHistoryMenu",
            name: "Global Transaction History",
            color : "#ff5741",
            style : "settingsGroupMenu",
            link: "/globalTransactionHistory",
        }
    }

    content() : contextEntry {
        return {
            key:"globalTransactionHistoryContext",
            title: "Global Transaction History",
            content: <GlobalTransactionHistory />,
            path : "/globalTransactionHistory"
        }
    }
    
}