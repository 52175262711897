import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import React from "react";
import { ReactNode } from "react";

interface modelOptions {
  title: string,
  body?: string | JSX.Element | ReactNode,
  postBody?: string | JSX.Element | ReactNode,
  confirmButtonString: string,
  confirmType: "success" | "error",
  confirmIcon?: "add" | "update" | "delete"
  form?: JSX.Element | null,
  hideButton?: boolean,
  callback?: any,
  cancelCallback?: any,
  show: [boolean, any]
}

export function ModalTemplate(options: modelOptions): JSX.Element {

  let form: JSX.Element | null = options.form !== undefined ? options.form : null;
  let confirmButton: JSX.Element;
  let confirmIcon!: IconName;

  switch (options.confirmIcon) {
    case "add": {
      confirmIcon = "file-circle-plus";
      break;
    }
    case "update": {
      confirmIcon = "pen-to-square";
      break;
    }
    case "delete": {
      confirmIcon = "trash-can";
      break;
    }
    default: {
      confirmIcon = "check"
      break;
    }
  }

  confirmButton = (<Button variant="outlined" color={options.confirmType} onClick={() => { options.callback() }}><FontAwesomeIcon icon={["fas", confirmIcon]} />&nbsp;{options.confirmButtonString}</Button>)

  return (
    <Dialog
      open={options.show[0]}
      onClose={() => { options.show[1](false) }}
      PaperProps={{
        component: options?.hideButton === false ? 'form' : 'div',
      }}
    >
      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <Grid item xs>
          <DialogTitle>{options.title}</DialogTitle>
        </Grid>
        <Grid item xs>
          <DialogContent>
            {options.body !== undefined ? <React.Fragment>{options.body}</React.Fragment> : undefined}
            {form}
            {options.postBody !== undefined ? <React.Fragment>{options.postBody}</React.Fragment> : undefined}
          </DialogContent>
        </Grid>
        <DialogActions>
          <Grid item xs>
            {form != null || (options?.hideButton === true) ? null : confirmButton}
          </Grid>
          <Grid item xs>
            { (options?.hideButton === true) ? null : <Button variant="outlined" color="primary" onClick={() => { options.show[1](false) }}>Cancel</Button> } 
          </Grid>
        </DialogActions>
      </Grid>

    </Dialog>
  )
}