import { Box, Button, FilledInput, FormControl, FormHelperText, Grid, IconButton, InputLabel, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form"
import { Add, Remove, SystemUpdateAlt } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

type scenarios = {
    scenario: {
        data: string,
        prizeDivision: number,
        _id?: string
    }[]
}

export default function GamesListEditForm(props: {
    data: {
        get: [], set: React.Dispatch<React.SetStateAction<{}[]>>
    }, confirm: React.Dispatch<React.SetStateAction<boolean>>
    length : number
}) {

    useEffect(() => {

        setfilterDataArrayCount(props.length);


        if(props.length !== filterDataArrayCount) {
            reset();
            props.data.get.forEach((e)=>{
                append(e);
            })
        }
      }, [props.data.get,props.length])

    const {unregister, register, control, setValue, getValues, reset } = useForm<scenarios>({
        defaultValues: {
            scenario: []
        }
    });

    const getFieldIndexs = async ()=>{
        await new Promise((resolve)=>{
            let fieldIndex = fields.map((e,i)=>{
                return i
            })
            fieldIndex.forEach((e)=>{
                remove(e);
            });
        })
    }

    const [filterDataArrayCount, setfilterDataArrayCount] = useState<number>(0)

    let { fields, append, remove } = useFieldArray({
        name: 'scenario',
        control
    })

    const onSubmit = () => {
        updateData();
        props.confirm(true);
    }
    const appendField = () => { append({ data: "", prizeDivision: 0 }) }
    const deleteField = (data: any) => { remove(data) }

    const updateData = () => {
        props.data.set([...getValues().scenario]);
    }

    if(filterDataArrayCount > 0) {
        return (
            <React.Fragment>
                {fields.map((field, index, array) => {
                    return (
                        <Box key={field._id} paddingBottom={3} sx={{ display: 'flex', flexWrap: 'wrap' }} >
                            <Typography variant="caption" paddingBottom={1}>{field._id !== undefined ? field._id : "pending"}</Typography>
                            <Grid
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                container
                            >
                                <Grid item xs={5}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel htmlFor="Scenario">Scenario</InputLabel>
                                        <FilledInput id="Scenario" defaultValue={field.data} {...register(`scenario.${index}.data` as const)} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel htmlFor="Prize Division">Prize Division</InputLabel>
                                        <FilledInput id="Prize Division" defaultValue={field.prizeDivision} {...register(`scenario.${index}.prizeDivision` as const)} />
                                    </FormControl>
                                </Grid>
                                {array.length > 1 ? (
                                    <Grid item><IconButton onClick={() => (deleteField(index))} aria-label="remove"><Remove /></IconButton>
                                    </Grid>
                                ) : null}
                                {index === array.length - 1 ? (
                                    <Grid item><IconButton onClick={appendField} aria-label="add"><Add /></IconButton>
                                    </Grid>
                                ) : null}
                            </Grid >
                        </Box >
                    )
                })}
                <Button onClick={() => { onSubmit() }} variant="contained" color="primary" startIcon={<SystemUpdateAlt />}>Submit Changes</Button>
            </React.Fragment >
        )
    } else {
        return null;
    }
    
}