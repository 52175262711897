import React, { useEffect, useState } from "react";
import GameListController from "./controller/GameListController";
import { Container, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GameEditListScenario from "./views/subviews/GameListEditScenario";
import { useNavigate } from "react-router-dom";
import SearchBar from "../instances/searchBar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ModalTemplate } from "../instances/modal";
import { formTemplate } from "../instances/form";
import { IResponse } from "../root/data/MainContainerData";
import { Modal } from "bootstrap";
import { FileDropForm } from "../instances/fileDropForm";

export default function GameList() {
    const controller: GameListController = new GameListController();

    let data: any;
    let setData: any;
    [data, setData] = useState([]);
    let navigate = useNavigate();

    let displayedData: any
    let setDisplayedData: any;
    [displayedData, setDisplayedData] = useState([]);

    const [showOverwriteTableModal, setShowOverwriteTableModal] = useState<boolean>(false);
    const [showOverwriteStructModal, setShowOverwriteStructModal] = useState<boolean>(false);


    let [showDuplicationModal, setShowDuplicationModal] = useState<boolean>(false);
    let [duplicationID, setDuplicationID] = useState<number>(0);
    let [selectedGameID, setSelectedGameID] = useState<string>("");

    const [ready, setReady] = useState(false);

    const [id, setID] = useState<string | number>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setID(event.currentTarget.id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!ready) {
            getgameList();
            setReady(true);
        }

    }, [ready])

    async function getgameList() {
        await controller.getData().then((newData) => {
            setData(newData)
            updateGamesList(newData);
        });
    }

    async function updateGamesList(updatedData: any) {
        setDisplayedData(updatedData);
    }

    function gamesListElement(element: any) {
        return (
            <TableRow key={element.id}>
                <TableCell>{element.id}</TableCell>
                <TableCell>{element.name}</TableCell>
                <TableCell align="right">{actionMenu(element)}</TableCell>
            </TableRow>
        )
    }

    function gamesListContainer() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Game ID</TableCell>
                            <TableCell>Game Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.length > 0 ? displayedData.map((e: any, i: number) => gamesListElement(e)) : null}
                    </TableBody>
                </Table>
            </TableContainer >
        )
    }

    function DuplicationConfirmModal(): JSX.Element {
        const duplicateGame = async (data: { id: number, data: string | boolean }[]) => {
            let body: any;
            let success: boolean = false;

            await controller.duplcate(
                parseInt(data[1].data as string),
                duplicationID,
                data[0].data,
                data[2].data as boolean
            ).then((res: any) => {
                body = res.responseData.data;
                success = true;
            }).catch((error: IResponse) => {
                body = error.responseData.data;
                success = true;
            }).finally(() => {
                controller.sendToastMessage({
                    body: body,
                    success: success
                });
                setShowDuplicationModal(false);
                setReady(false);
            });
        }

        return <ModalTemplate
            title="Duplicate Game"
            body={`Enter a New Data for Duplicated Game`}
            confirmButtonString="Create New PricePoint"
            confirmType="success"
            confirmIcon="add"
            form={formTemplate({
                formGroup: [[{
                    label: "Duplicated Game Name",
                    id: "name",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Enter a valid Name",
                    options: {
                        type: "string",
                        placeholder: "Please Enter Duplicated Game name"
                    },
                }],
                [{
                    label: "Duplicated Game ID",
                    id: "id",
                    feedbackSuccess: "This ID looks ok!",
                    feedbackInvalid: "Enter a valid ID",
                    options: {
                        type: "number",
                        placeholder: "Please Enter Duplicated Game ID"
                    },
                }],
                [{
                    label: "Copy Mechanics?",
                    id: "copyMechanics",
                    feedbackSuccess: "This ID looks ok!",
                    feedbackInvalid: "Enter a valid ID",
                    options: {
                        type: "switch",
                    },
                }]],
                submitString: "Duplicate",
                onSubmit: duplicateGame,
            })}
            show={[showDuplicationModal, setShowDuplicationModal]}
        />
    }

    function overwriteTableModal() {
        return <ModalTemplate
            title=""
            body={<FileDropForm
                title="Overwrite PrizeTable Data"
                message="Upload CSV file that contains the new PrizeTable Data you wish to overwrite the old one with."
                controller={controller}
                confirm={setShowOverwriteTableModal}
                name="PrizeTable Data"
                fileName="table"
                submit={async (data : FormData)=>{await overwriteTableOnSubmit(data)}}
            />}
            show={[showOverwriteTableModal, setShowOverwriteTableModal]}
            confirmButtonString="Submit"
            confirmType="success"
            hideButton={true}
        />
    }

    function overwriteStructModal() {
        return <ModalTemplate
            title=""
            body={<FileDropForm
                title="Overwrite PrizeStructure Data"
                message="Upload CSV file that contains the new PrizeStructure Data you wish to overwrite the old one with."
                controller={controller}
                confirm={setShowOverwriteStructModal}
                name="PrizeStructure Data"
                fileName="struct"
                submit={async (data : FormData)=>{await overwriteStructOnSubmit(data)}}
            />}
            show={[showOverwriteStructModal, setShowOverwriteStructModal]}
            confirmButtonString="Submit"
            confirmType="success"
            hideButton={true}
        />
    }

    async function overwriteTableOnSubmit(data : FormData) : Promise<void> {
        let body: any;
        let success: boolean = false;

        await controller.overWritePrizeTable(
            selectedGameID,
            data as FormData
        ).then((data: string) => {
            body = data;
            success = true;
        }).catch((data: string) => {
            body = data;
            success = true;
        }).finally(() => {
            controller.sendToastMessage({
                body: body,
                success: success
            });
            setShowDuplicationModal(false);
            setReady(false);
        });
    }

    async function overwriteStructOnSubmit(data : FormData) : Promise<void> {
        let body: any;
        let success: boolean = false;

        await controller.overWritePrizeStructure(
            selectedGameID,
            data as FormData
        ).then((data: string) => {
            body = data;
            success = true;
        }).catch((data: string) => {
            body = data;
            success = true;
        }).finally(() => {
            controller.sendToastMessage({
                body: body,
                success: success
            });
            setShowDuplicationModal(false);
            setReady(false);
        });
    }

    function actionMenu(element: any) {
        return (
            <Tooltip title="Actions" placement="right-start">
                <IconButton
                    aria-label="more"
                    id={element.id}
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
        )
    }

    function actionMenuDropdown() {
        return (
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open && (anchorEl == null ? false : anchorEl.id === id)}
                onClose={handleClose}
            >
                <MenuItem id={`${id}_scenario`} key={`${id}_scenario`} onClick={() => { navigate("/gameListEditScenario/" + id); }}>Update Scenarios</MenuItem>
                <MenuItem id={`${id}_pricePoints`} key={`${id}_pricePoints`} onClick={() => { navigate("/gameListEditPricePoints/" + id); }}>Update PricePoints</MenuItem>
                <MenuItem id={`${id}_duplicate`} key={`${id}_duplicate`} onClick={() => { setDuplicationID((id as number)); setShowDuplicationModal(true) }} > Duplicate </MenuItem>
                <MenuItem id={`${id}_overWritePrizeTable`} key={`${id}_overWritePrizeTable`} onClick={() => { setDuplicationID((id as number)); setShowOverwriteTableModal(true); setSelectedGameID(id as string); }} > Overwrite PrizeTable Data </MenuItem>
                <MenuItem id={`${id}_overWritePrizeStructure`} key={`${id}_overWritePrizeStructure`} onClick={() => { setDuplicationID((id as number)); setShowOverwriteStructModal(true); setSelectedGameID(id as string) }} > Overwrite PrizeStructure Data </MenuItem>
            </Menu>
        )
    }


    function body() {
        return (
            <Container>
                {overwriteTableModal()}
                {overwriteStructModal()}
                {actionMenuDropdown()}
                <SearchBar dataSource={data} output={updateGamesList} fields={["id", "name"]} limit={3} />
                {gamesListContainer()}
                <DuplicationConfirmModal />
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Games List",
        helpString: (<React.Fragment>Find your desired game below and select to update scenario data, price points and duplicate game setups."</React.Fragment>)
    });
}