import { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, List, ListItemButton, Table } from "@mui/material";
import PlayersController from "../controller/PlayersController";
import { useParams, useSearchParams } from "react-router-dom";
import GlobalDatasetsController from "../../GamePlayOptions/controller/GamePlayOptionsController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayersPropertiesController from "../controller/PlayerPropertiesController";
import { appContext } from "../../root/MainContainer";
import SearchBar from "../../instances/searchBar";
import React from "react";

export default function PlayerGameplayOptions() {
    const controller: PlayersController = new PlayersController();
    const propertiesController : PlayersPropertiesController = new PlayersPropertiesController();
    const globalDatasetsController = new GlobalDatasetsController();
    const [ready, setReady] = useState(false);
    const params: any = useParams();
    let id: string = params.id;

    let properties: any;
    let setProperties: any;
    [properties, setProperties] = useState([]);

    let globalDatasets: any;
    let setGlobalsDatasets: any;
    [globalDatasets, setGlobalsDatasets] = useState([]);

    let filteredGlobalDatasets: any;
    let setFilteredGlobalsDatasets: any;
    [filteredGlobalDatasets, setFilteredGlobalsDatasets] = useState([]);

    let selectedDataSet: any;
    let setSelectedDataSet: any;
    [selectedDataSet, setSelectedDataSet] = useState({});

    const sendToastMessage = useContext(appContext);

    useEffect(() => {
        if (!ready) {
            getPlayerData();
            getGlobalDataSets();
            setReady(true);
        }
    }, [ready]);

    async function getPlayerData() {
        try {
            await propertiesController.getPlayerProperties(id,setReady).then((data)=>{
                setProperties(data);
            }).catch((err) => {
                sendToastMessage({
                    body : `error: ${err}`,
                    success: false
                });
            });
        } catch (err: any) {
            
        }
    }

    async function getGlobalDataSets() {
        try {
            await globalDatasetsController.getData().then((data)=>{
                setFilteredGlobalsDatasets(data);
                setGlobalsDatasets(data);
            }).catch((data) => {
                throw data;
            });
        } catch (err: any) {
            sendToastMessage({
                body : `error: ${err}`,
                success: false
            });
        }
    }

    async function updateFilteredData(updatedData: any) {
        setFilteredGlobalsDatasets(updatedData);
    }

    function playerPropertiesView() {
        return (
            <Grid>
                <h4>Player Properties</h4>
                <Grid>
                    <Table>
                        <thead key="gameplayOptionsHead">
                            <tr>
                                <th key="key_header">Key</th>
                                <th key="value_header">Value</th>
                                <th key="blank_header"></th>
                            </tr>
                        </thead>
                        <tbody key="gameplayOptionsBody">
                            {properties.map((e: any) => {
                                return (
                                    <tr key={`row_${e.key}`}>
                                        <td key={`key_${e.key}`}>{e.key}</td>
                                        <td key={`value_${e.key}`}>{e.value}</td>
                                        <td key={`delete_${e.key}`}><Button color="error" onClick={() => { removePropertyFromPlayer(e.key); }}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp;Remove</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Grid>
            </Grid>
        )
    }

    function globalDataSetsView() {
        return (
            <Grid >
                <h4>Gameplay Options</h4>
                <Grid className="dataContainer--half">
                    <h5>Keys</h5>
                    <List>
                        {filteredGlobalDatasets.map((e: any) => {
                            const active = findSelected(e._id);
                            return (<ListItemButton onClick={() => { updatedSelectedData(e) }}>{e.title}</ListItemButton>)
                        })}
                    </List>
                </Grid>
                <Grid className="dataContainer--half">
                    <h5>Values</h5>
                    <List>
                        {(Object.keys(selectedDataSet).length > 0  && selectedDataSet.container.length > 0  )? selectedDataSet.container.map((e: any) => {
                            return (
                                <><ListItemButton key={`id_${e}`}>{e}<Button className="me-3 float-end" color="success" onClick={() => { setPropertyOnPlayer(selectedDataSet.title,e); }}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp;Set On Player</Button></ListItemButton></>
                            )
                        }) : null}
                    </List>
                </Grid>
            </Grid>
        )
    }

    function findSelected(id: string): boolean {
        if (id === selectedDataSet._id) {
            return true
        } else {
            return false;
        }
    }

    function updatedSelectedData(Selected: {}) {
        setSelectedDataSet(Selected);
        setReady(false);
    }

    async function setPropertyOnPlayer(title : string, selectedData : any) {
        let newData : any = properties;
        let found : boolean = false;
        newData.forEach((e : any)=>{
            if(e.key === title) {
                e.value = selectedData;
                found = true;
            }
        });

        if(found === false) {
            newData.push({key : title, value : selectedData})
        }

        await propertiesController.updatePlayerProperties(
            id as string,
            newData,
            setReady
        ).then(()=>{
            setProperties(newData);
            setReady(false);
            sendToastMessage({
                body : `set ${selectedData} on player`,
                success: true
            })
        });
        
    }

    async function removePropertyFromPlayer(key : string) {
        let updatedData : any = [];
        properties.forEach((e : any)=>{
            if(e.key !== key) {
                updatedData.push({key : e.key, value : e.value})
            }
        });

        setProperties(updatedData);
        await propertiesController.updatePlayerProperties(
            id as string,
            updatedData,
            setReady
        );
        setReady(false);
    }

    function body(): JSX.Element {
        return (
            <Container>
                <SearchBar dataSource={globalDatasets} output={updateFilteredData} fields={["title"]} limit={3} />
                {globalDatasets.length > 0 ? globalDataSetsView() : null}
                {properties.length > 0 ? playerPropertiesView() : null}
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Set Gameplay Options",
        helpString: (<React.Fragment></React.Fragment>)
    })


}