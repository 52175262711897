import Controller from "../../Controller";
import { IForceData } from "../../GameList/data/GameListData";
import { IEmptyResult, IResponse } from "../../root/data/MainContainerData";

export default class ForceDataController extends Controller {

    constructor() {
        super();
        this.route = "admin/force";

    }

    public getForceData(playerID: string, gameID : number) : Promise<IForceData | null>   {
        return new Promise(async (resolve, reject)=>{
            await this.sendRequest({method : "GET", params : playerID, query:[{
                name : "gameID",
                value : gameID.toString()
            }]}).then((data : IResponse)=>{
                if(this.isEmptyResult(data.responseData.data)) {
                    return resolve(null);
                }
                return resolve((data.responseData.data) as IForceData);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }
    
    public setForceData(data : IForceData, newData? : boolean) {
        let method = newData ? "POST" : "PUT";
        return new Promise(async (resolve, reject)=>{
            await this.sendRequest({method : method, params : data.playerID, query:[{
                name : "gameID",
                value : data.gameID.toString()
            }]},{
                scenarioID : data.scenarioID,
                sticky : data.sticky,
            }).then((data : IResponse)=>{
                return resolve((data.responseData.data) as IForceData);
            }).catch((err)=>{
                return reject(err);
            })
        });
    }
    
    public clearForceDataArray(id: string, gameID: number) : Promise<unknown> {
        return new Promise(async(resolve,reject)=>{
            await this.sendRequest({method : "DELETE", params : id, query: [{
                name : "gameID",
                value : gameID.toString()
            }]}).then((data : IResponse)=>{
                return resolve(data.responseData.data as string);
            }).catch((err)=>{
                return reject(err);
            })
        })
    }
} 

