import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import GlobalDatasets from "../GamePlayOptions";

export default class GamePlayOptionsController extends Controller {
    constructor() {
        super();
        this.route = 'admin/globals';
    }

    getData() {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", full: false }).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    createNewEntry(id: string) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "POST", params: id },
            ).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    updateEntry(id: string, inputData: any) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                url: this.route + "/update",
                params: id
            },
                {
                    data: inputData
                }
            ).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    deleteEntry(keys: string[]) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "OPTIONS", }, {
                keys: keys
            }).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    menu(): menuEntry {
        return {
            key: "globalsMenu",
            name: "Global Gameplay Options",
            color: "#ff5741",
            style: "dataGroupMenu",
            link: "/globals",
        }
    }

    content(): contextEntry {
        return {
            key: "globalsContext",
            title: "Global Gameplay Options",
            content: <GlobalDatasets controller={this} />,
            path: "/globals"
        }
    }
}