import { Box, Button, Container, Divider, Grid, IconButton, List, ListItem, ListItemIcon, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import GamePlayOptionsController from "./controller/GamePlayOptionsController";
import { Component, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTemplate } from "../instances/modal";
import { formTemplate, formGroupTemplate, formGroupOptions, IformData } from "../instances/form";
import { appContext } from "../root/MainContainer";
import SearchBar from "../instances/searchBar";
import React from "react";
import { ArrowRight, MoreVert } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';


interface IProps {
    controller: GamePlayOptionsController
}

export default function GamePlayOptions(props: IProps) {
    let controller = props.controller;

    let [ready, setReady] = useState(false);
    let [showConfirmModal, setShowConfirmModal] = useState(false);

    const sendToastMessage = useContext(appContext);

    let globalsData: any;
    let setGlobalsData: any;
    [globalsData, setGlobalsData] = useState([]);

    let filteredGlobalsData: any;
    let setfilteredGlobalsData: any;
    [filteredGlobalsData, setfilteredGlobalsData] = useState([]);

    let selectedData: any | null;
    let setSelectedData: any;
    [selectedData, setSelectedData] = useState(null);

    let groupData: any | null;
    let setGroupData: any;
    [groupData, setGroupData] = useState([]);

    let [formData, setFormData] = useState<IformData | []>([]);

    let showDeleteModal: any | null;
    let setShowDeleteModal: any;
    [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        if (!ready) {
            getData();
            setReady(true);
        }
    },
        [ready])

    async function getData() {
        await controller.getData().then((newData) => {
            setGlobalsData(newData)
            setfilteredGlobalsData(newData);
        });
    }

    async function filteredData(updatedData: any) {
        setfilteredGlobalsData(updatedData);
    }

    function confirmModel() {
        return ModalTemplate({
            title: "Add New Global Data Set",
            body: "Enter title for new Global Data set",
            confirmButtonString: "Add",
            confirmType: "success",
            confirmIcon: "add",
            form: formTemplate({
                formGroup: [[{
                    label: "Global Data Set Name",
                    id: "id",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Enter a valid Global Data Set name",
                    options: {
                        type: "string",
                        placeholder: "Please Enter Name"
                    },
                }, {
                    label: "Global Data Set Type",
                    id: "type",
                    feedbackSuccess: "This looks ok!",
                    feedbackInvalid: "Enter a data set Type",
                    options: {
                        type: "select",
                    },
                    selectGroupOptions: [
                        { name: "string", value: "string" },
                        { name: "number", value: "number" },
                        { name: "boolean", value: "boolean" },
                        { name: "array", value: "array" },
                    ]
                }]],
                onSubmit: submitCreateNewGroup,
                submitString: "Submit",
            }),
            show: [showConfirmModal, setShowConfirmModal]
        })
    }

    async function submitCreateNewGroup(callbackData: any) {
        let body : string = "";
        let success : boolean = false;
        await controller.createNewEntry(
            callbackData[0].data,
        ).then((data : any) => {
            body = data.responseData.data;
            success = true;
        }).catch((data : any) => {
            body = data.responseData.data;
            success = false;
        }).finally(()=>{
            sendToastMessage({
                body: body,
                success: success
            });
            setShowConfirmModal(false);
            setReady(false);
        })
    }

    async function updateGroup(callbackData: any) {
        await controller.updateEntry(
            selectedData.title,
            callbackData.map((e: any) => { return e.data })
        )
        setReady(false);
        sendToastMessage({
            body: `${selectedData.title} Updated!`,
            success: true
        });
    }

    function formGroupProps(e: any,i : number) {
        return [{
            label: '',
            id: i.toString(),
            feedbackSuccess: "This name looks ok!",
            feedbackInvalid: "Enter a valid Global Data Set name",
            options: {
                type: "string",
                placeholder: e !== undefined ? e : `Enter new value...`,
            },
            default: e !== undefined ? e : "",
        }]
    }

    function addNewRow(data : string | undefined) {
        setSelectedData({...selectedData, container: [...selectedData.container,data]});
    }

    function editDataValues() {
        if (selectedData.container.length === 0) {
            addNewRow(undefined);
        }
        let data = selectedData.container.map((e: any, i : number) => { return formGroupProps(e,i) });
        let valueFormTemplate = formTemplate({
            formGroup: data,
            submitString: "Update",
            onUpdate: updateGroup,
        })
        return (
            <Box>
                <Typography variant="h5">{selectedData.title}</Typography>
                {valueFormTemplate}
                <Grid container paddingTop={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Divider />
                        {newRowButton()}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function deleteKeyModal() {
        let keys = groupData.map((e: string) => {
            return <ListItem key={`${e}_delete_list`}>
                <ListItemIcon>
                    <ArrowRight />
                </ListItemIcon> {e} </ListItem>
        })
        let keyList = <List>{keys}</List>

        return <ModalTemplate title={"Delete Selected Keys?"} confirmButtonString={"Delete"} confirmType={"error"} callback={()=>{deleteKey()}} show={[showDeleteModal, setShowDeleteModal]} body={keyList} />
    }

    async function deleteKey() {
        let body = "";
        let success = false;

        await controller.deleteEntry(groupData)
        .then((data : any)=>{
            body = data.responseData.data;
            success = true;
        })
        .catch((data : any)=>{
            body = data.responseData.data;
            success = false;
        })
        .finally(()=>{
            controller.sendToastMessage({
                body: body,
                success: success
            });
            setReady(false);
            setShowDeleteModal(false);
        });
    }

    function newRowButton(): JSX.Element {
        return (
            <Box paddingTop={5}>
                <Button color="primary" variant="outlined" onClick={() => addNewRow(undefined)}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp; New Row</Button>
            </Box>
        )
    }

    function deleteKeyButton() {
        return (
            <Button color="error" variant="outlined" onClick={() => setShowDeleteModal(true)}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp; Delete Selected</Button>
        )
    }

    function keyGroup() {
        return (
            <Box>
                <div style={{ height: 632, width: '100%' }}>
                    <DataGrid
                        rows={filteredGlobalsData}
                        getRowId={(row) => row.title}
                        columns={[
                            { field: 'title', headerName: 'Title', width: 370 },
                            {
                                field: 'locked', headerName: 'Locked', width: 100, renderCell: (params: any) => {
                                    return (
                                        <React.Fragment>
                                            <GridActionsCellItem
                                                icon={params.row.lock ? <LockIcon /> : <LockOpenIcon />}
                                                label="Locked"
                                            />
                                        </React.Fragment>
                                    );
                                }
                            }
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        onRowClick={(params: GridRowParams) => {
                            setSelectedData(null);
                            setTimeout(() => {
                                setFormData(params.row.container.map((e : any, i: number)=>{
                                    return {
                                        id : i.toString(),
                                        data : e
                                    }
                                }))
                                setSelectedData(params.row);
                            }, 100)

                        }}
                        onRowSelectionModelChange={(params: any) => {
                            let tmp: any[] = params.map((e: string) => { return e });
                            setGroupData(tmp);

                        }}
                        isRowSelectable={(params: GridRowParams) => !params.row.lock}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </div>
            </Box>
        );
    }

    function body() {
        return (
            <Container>
                <SearchBar dataSource={globalsData} output={filteredData} fields={["title"]} limit={3} />
                <Grid container paddingTop={5} spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={{
                            class: "dataContainer"
                        }}>
                            {filteredGlobalsData.length > 0 ? keyGroup() : null}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {selectedData !== null ? editDataValues() : null}
                    </Grid>
                </Grid>

                <Box paddingTop={5}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item>
                            <Button className="me-3 float-end" variant="outlined" color="success" onClick={() => { setShowConfirmModal(true) }}><FontAwesomeIcon icon={["fas", "file-circle-plus"]} />&nbsp;Create New Global</Button>
                        </Grid>
                        <Grid item>
                            {groupData.length > 0 ? deleteKeyButton() : null}
                        </Grid>
                        {deleteKeyModal()}
                        {confirmModel()}
                    </Grid>
                </Box>
            </Container>
        )
    }


    return controller.renderLayout(null, body(), {
        title: "Global Gameplay Options",
        helpString: (<React.Fragment></React.Fragment>)
    });

}