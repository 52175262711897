import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { appContext } from "../root/MainContainer";
import GlobalTransactionHistoryController from "./controller/GlobalTransactionHistoryController";
import SearchBar from "../instances/searchBar";

export default function GlobalTransactionHistory() {
    const [ready, setReady] = useState(false);
    const controller: GlobalTransactionHistoryController = new GlobalTransactionHistoryController();

    const sendToastMessage = useContext(appContext);

    const params: any = useParams();
    let id: string = params.id;

    let transactionData: any;
    let setTransactionData: any;
    [transactionData, setTransactionData] = useState([]);

    let filteredTransactionData: any;
    let setFilteredTransactionData: any;
    [filteredTransactionData, setFilteredTransactionData] = useState([]);


    useEffect(() => {
        if (!ready) {
            getTransactions();
            setReady(true);
        }
    }, [ready]);

    async function getTransactions() {
        await controller.getAllTransactions().then((data) => {
            setTransactionData(data);
            setFilteredTransactionData(data);
        }).catch((err) => {
            sendToastMessage({
                body: `error: ${err}`,
                success: false
            });
        });
    }

    async function updateFilteredData(updatedData: any) {
        setFilteredTransactionData(updatedData);
    }

    function transactionElement(data: any) {
        let date = new Date(data.created).toString();;
        return (
            <React.Fragment></React.Fragment>
            // <Col key={data.id}>
            //     <Accordion>
            //         <Accordion.Item eventKey="0">
            //             <Accordion.Header>
            //                 {data.id}
            //             </Accordion.Header>
            //             <Accordion.Body>
            //                 <Table>
            //                     <tbody>
            //                         <tr>
            //                             <td><strong>Username</strong>: `{data.username}`</td>
            //                         </tr>
            //                         <tr>
            //                             <td><strong>GameID</strong>: `{data.gameID}`</td>
            //                         </tr>
            //                         <tr>
            //                             <td><strong>WagerType</strong>: `{data.wagerType}`</td>
            //                         </tr>
            //                         <tr>
            //                             <td><strong>Status</strong>: `{data.status}`</td>
            //                         </tr>
            //                         <tr>
            //                             <td><strong>Created</strong>: `{date}`</td>
            //                         </tr>
            //                     </tbody>
            //                 </Table>
            //             </Accordion.Body>
            //         </Accordion.Item>
            //     </Accordion>
            // </Col>
        )
    }

    function body() {
        return (
            <React.Fragment></React.Fragment>
            // <Container>
            //     <br />
            //     <SearchBar dataSource={transactionData} output={updateFilteredData} fields={["id", "username", "gameID", "wagerType", "status"]} limit={3} />
            //         {transactionData.length > 0 ? filteredTransactionData.map((e: any) => { return transactionElement(e) }) : null}
            // </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Global Transaction History",
        helpString: (<React.Fragment></React.Fragment>)
    });
}

