import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Pagination } from "@mui/material";
import GameListController from "../../controller/GameListController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { IformData, formTemplate } from "../../../instances/form";
import { ModalTemplate } from "../../../instances/modal";
import { IScenario } from "../../data/GameListData";
import GamesListEditForm from "../../instances/GameListEditForm";
import SearchBar from "../../../instances/searchBar";

export default function GameListEditScenario() {
    let controller = new GameListController();
    const [dataArray, setDataArray] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [ready, setReady] = useState(false);
    const [onPage, setOnPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const params: any = useParams();
    let id: number = params.gameid;

    useEffect(() => {
        if (!ready) {
            fetchData();
            setReady(true);
        }
    }, [ready,skip,filteredData]);

    async function fetchData() {
        await controller.getMechanic(id, skip).then((data) => {
            setTotal((data as { results: IScenario[], total: number }).total);
            setDataArray((data as { results: IScenario[], total: number }).results);
            setFilteredData((data as { results: IScenario[], total: number }).results);
        }).finally(() => {
            setReady(true);
        });
    }

    async function handleEdit() {
        let body: string = "";
        let success: boolean = false;
        await controller.updateMechanic(id, dataArray.map((e: {}) => e))
            .then((data: any) => {
                body = data.data;
                success = true;
            }).catch((data: any) => {
                body = data;
                success = false;
            }).finally(() => {
                setShowEditModal(false);
                controller.sendToastMessage({
                    body: body,
                    success: success
                });
            });
    }

    function confirmEditDataModal(): JSX.Element {
        return <ModalTemplate title="Confirm Edit"
            body="Are you sure you wish to edit this data"
            callback={handleEdit}
            confirmButtonString="Accept"
            confirmType="success"
            show={[showEditModal, setShowEditModal]}
        />
    }

    function parseData() {
        if (dataArray.length <= 0) {
            return null
        }

        return (    
            <GamesListEditForm data={{ get: filteredData, set: setDataArray }} confirm={setShowEditModal} length={filteredData.length} />
        )
    }

    function PaginationBar() {
        let show = Math.floor(total / 10) > 0;

        const onChange = (event: React.ChangeEvent<unknown>, page: number) => {
            setOnPage(page);
            setSkip(page !== 0 ? ((page - 1) * 10) : 0);
            setReady(false);
        }

        return show ? <Pagination onChange={onChange} page={onPage} count={Math.floor(total / 10)+1} variant="outlined" /> : null
    }

    function body() {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                <Grid item xs={12}><SearchBar dataSource={dataArray} output={setFilteredData} fields={["data"]} limit={3} /></Grid>
                {confirmEditDataModal()}
                <Grid item xs={12}>
                    {parseData()}
                </Grid>
                <Grid item xs={12}>
                    <PaginationBar />
                </Grid>
            </Grid>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Update Scenarios",
        helpString: (<React.Fragment>Click in the appropriate boxes to edit scenario data and prize division (divisions should be between 1 and 99). Click ‘+’ to add new blank scenarios"</React.Fragment>)
    })
}

