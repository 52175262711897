import GameListController from "../../GameList/controller/GameListController";
import { useEffect, useState } from "react";
import ForceDataController from '../controller/ForceDataController';
import { useParams } from 'react-router-dom';
import React from "react";
import IPaytable, { IForceData, IMechanic, IScenario } from "../../GameList/data/GameListData";
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, Grid, IconButton, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ChevronRight, Close } from "@mui/icons-material";
import SearchBar from "../../instances/searchBar";
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTemplate } from "../../instances/modal";
import { v4 as uuidv4 } from 'uuid';


export default function ForceData() {
    const controller: ForceDataController = new ForceDataController();
    const gamesListController: GameListController = new GameListController();

    const [gamesListData, setGamesListData] = useState<{ name: string, id: number }[] | null>(null);

    const [scenarios, setScenarioList] = useState<IScenario[] | null>(null);
    const [filteredScenarios, setFilteredScenarios] = useState<IScenario[] | null>(null);
    const [queuedScenarios, setQueuedScenarios] = useState<(IScenario & {sortID: string})[] | null>(null);

    const [forceData, setForceData] = useState<IForceData | null>(null);

    const [selectedGame, setSelectedGame] = useState<IPaytable | null>(null);
    const [showConfirmForceDataModal, setShowConfirmForceDataModal] = useState<boolean>(false);
    const [showClearForceDataModal, setShowClearForceDataModal] = useState<boolean>(false);
    const [ready, setReady] = useState(false);

    const [sticky, setSticky] = useState<boolean>(false);

    const params: any = useParams();
    let playerID: string = params.id

    useEffect(() => {
        if (!ready) {
            if (gamesListData === null) {
                getGameData();

            }
        }
        if (selectedGame !== null && scenarios === null) {
            getScenarios();
        }
        if (selectedGame !== null && scenarios !== null && forceData === null) {
            getForceData();
        }
        setReady(true);
    }, [ready, selectedGame, scenarios, queuedScenarios])

    async function getGameData(): Promise<void> {
        await gamesListController.getData().then((data: { name: string, id: number }[] | string) => {
            setGamesListData([...(data as { name: string, id: number }[])]);
        });
    }

    async function getScenarios(): Promise<void> {
        await gamesListController.getMechanic((selectedGame as IPaytable).id,0).then(async (data) => {
            setScenarioList([...((data as {results : IScenario[], total: number}).results)]);
            setFilteredScenarios([...((data as {results : IScenario[], total: number}).results)]);
        });
    }

    async function getForceData(): Promise<void> {
        await controller.getForceData(playerID, (selectedGame as IPaytable).id).then((data: IForceData | null) => {
            if (data !== null) {
                setForceData({ ...(data as IForceData) });

                let scenarioData = data.scenarioID.map((e: string) => {
                    return scenarios?.find((el: IScenario) => el._id === e)
                }) as (IScenario & {sortID: string})[]

                setQueuedScenarios([...scenarioData]);
                setSticky(data.sticky);
            }
        });
    }

    async function onSetDataConfirm() {
        let success = false;
        let message = "";

        const newData: boolean = forceData == null ? true : false;
        const newForceData: IForceData = {
            playerID: playerID,
            gameID: (selectedGame as IPaytable).id,
            scenarioID: (queuedScenarios as IScenario[]).map((e: IScenario) => { return e._id! }),
            sticky: sticky,
            index: 0
        }

        await controller.setForceData((newForceData) as IForceData, newData)
            .then((data: any) => {
                success = true;
                message = data;
            }).catch((data: any) => {
                success = false;
                message = data;
            }).finally(() => {
                controller.sendToastMessage({
                    body: message,
                    success: success
                });
                setShowConfirmForceDataModal(false);
                setReady(false);
            });
    }

    async function onClearDataConfirm() {
        let success = false;
        let message = "";
        await controller.clearForceDataArray(playerID, (selectedGame as IPaytable).id)
            .then((data: any) => {
                success = true;
                message = data;
            }).catch((data: any) => {
                success = false;
                message = data;
            }).finally(() => {
                controller.sendToastMessage({
                    body: message,
                    success: success
                });
                setQueuedScenarios(null);
                setShowClearForceDataModal(false);
                setReady(false);
            });
    }

    function ConfirmForceDataModal(): JSX.Element {
        return <ModalTemplate
            title={`Confirm Setting ForceData on ${selectedGame?.name}?`}
            confirmButtonString="Confirm"
            confirmType="success"
            callback={onSetDataConfirm}
            show={[showConfirmForceDataModal, setShowConfirmForceDataModal]}
        />
    }

    function ClearForceDataModal(): JSX.Element {
        return <ModalTemplate
            title={`Confirm Clearing ForceData on ${selectedGame?.name}?`}
            confirmButtonString="Confirm"
            confirmType="success"
            callback={onClearDataConfirm}
            show={[showClearForceDataModal, setShowClearForceDataModal]}
        />
    }

    function GamesListSeclection(): JSX.Element | null {
        let game = (selectedGame as IPaytable);
        if (gamesListData === null) return null;

        return (
            <Grid container columnSpacing={{ xs: 5, sm: 5, md: 5 }}>
                <Grid item xs={12}>
                    <Typography variant="h5">Game Name {game != null ? `> ${game.name}` : ""}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                        disablePortal
                        id="GamesSelectionBox"
                        options={(gamesListData as { name: string, id: number }[]).map((e: { name: string, id: number }) => { return e.name })}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Games" />}
                        onSelectCapture={async (data: any) => {
                            if (game == null || (data.target as any).value !== (game).name) {
                                let foundData = (gamesListData as { name: string, id: number }[]).find((e: any) => {
                                    if (e.name === (data.target as any).value) {
                                        return e;
                                    }
                                })
                                if (foundData) {
                                    await gamesListController.getPaytable((foundData as IPaytable).id).then((data: IPaytable | string) => {
                                        setSelectedGame((selectedGame) => ({ ...(data as IPaytable) }));
                                    }) as IPaytable
                                }
                            }
                        }} />
                </Grid>
            </Grid>
        )
    }

    function ForceDataList(): JSX.Element | null {
        if (queuedScenarios === null) return null;

        function onDragEnd(data: any) {
            if (data.destination != null) {
                let tmp = (queuedScenarios as (IScenario & {sortID: string})[])[data.source.index];
                queuedScenarios?.splice(data.source.index, 1);
                queuedScenarios?.splice(data.destination.index, 0, tmp);
                setQueuedScenarios([...(queuedScenarios as (IScenario & {sortID: string})[])]);
            }
        }

        let forcedataContainers = queuedScenarios.map((e: IScenario & {sortID: string}, i: number) => {
            return <ForceDataContainer key={`scenario_${uuidv4()}`} data={e} index={i} />
        })
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided: DroppableProvided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {forcedataContainers}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    function ForceDataContainer(params: { data: IScenario & {sortID: string}, index: number }): JSX.Element {

        function onRemove(data: number) {
            queuedScenarios?.splice(data, 1);
            setQueuedScenarios([...(queuedScenarios as (IScenario & {sortID: string})[])]);
        }

        let showmore: boolean = false;

        let id = uuidv4();

        return (
            <Draggable key={id} draggableId={id!} index={params.index}>
                {(provided: DraggableProvided) => (
                    <Card sx={{
                        minWidth: 275,
                        p: 2,
                        margin: 'auto',
                        maxWidth: 500,
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }} 
                        key={params.data.sortID}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <CardHeader
                            action={
                                <Tooltip title="Remove data from force array" placement="right">
                                    <IconButton onClick={(data: any) => (onRemove(params.index))} aria-label="close">
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            }
                            title={`Division: ${params.data.prizeDivision}`}
                        />
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={10}> {
                                    (params.data.data.length > 50 && !showmore) ? params.data.data.substring(0, 35) : params.data.data}{
                                        !showmore ? (<a>...</a>) : null
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </Draggable>
        )
    }

    function ScenarioList(): JSX.Element | null {
        if (scenarios === null) return null;
        return (
            <Box>
                <div style={{ height: 662, width: '100%' }}>
                    <DataGrid
                        rows={(filteredScenarios as IScenario[])}
                        getRowId={(data: IScenario) => { return data._id! }}
                        columns={[
                            { field: 'prizeDivision', headerName: 'Prize Division', width: 100 },
                            { field: 'data', headerName: 'Data', width: 300 },
                            {
                                field: 'addToForce', headerName: 'Add', width: 50, renderCell: (data) => {
                                    return (
                                        <Tooltip title="Add data to force array" placement="right">
                                            <GridActionsCellItem
                                                icon=<ChevronRight />
                                                label="Locked"
                                                onClick={() => {
                                                    if(queuedScenarios === null) {
                                                        setQueuedScenarios([]);
                                                    }
                                                    let scenario : (IScenario & {sortID: string}) = [filteredScenarios?.find((e: IScenario) => e._id === data.id) as (IScenario & {sortID: string})].map((e)=>e)[0];
                                                    setQueuedScenarios(update => [...(update as (IScenario & {sortID: string})[]), scenario]);
                                                }}
                                            />
                                        </Tooltip>
                                    );
                                }
                            }
                        ]}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        slotProps={{
                            panel: {
                                sx: {
                                    [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                                        display: "none"
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </Box>
        );
    }

    function ButtonContainer(): JSX.Element | null {
        if (queuedScenarios === null) return null;

        function update(data: any) {
            setSticky(data.target.checked);
        }

        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                paddingTop={2}
            >
                <Tooltip title="Sticky Data is continually repeated in an ordered loop until data is cleared or sticky is switched off" placement="bottom">
                    <FormControl>
                        <FormControlLabel control={<Switch checked={sticky} onChange={(data) => { update(data) }} id={"stickyData_check"} />} label={"Sticky Data?"} />
                    </FormControl>
                </Tooltip>

                {forceData !== null ? <Button onClick={() => { setShowClearForceDataModal(true) }} variant="outlined" size="large" color="error" type="submit">Clear Data</Button> : null}
                <Button onClick={() => { setShowConfirmForceDataModal(true) }} variant="outlined" size="large" color="success" type="submit">Set Data</Button>
            </Stack>
        )
    }

    function body(): JSX.Element {
        return (
            <React.Fragment>
                <ConfirmForceDataModal />
                <ClearForceDataModal />
                <GamesListSeclection />
                {scenarios !== null ? <SearchBar dataSource={scenarios} fields={["prizeDivision"]} limit={3} output={setFilteredScenarios} /> : null}
                <Grid container spacing={{ xs: 2 }}>
                    <Grid item xs={6} >
                        <ScenarioList />
                        <ButtonContainer />
                    </Grid>
                    <Grid item xs={6} >
                        <ForceDataList />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Force Data",
        helpString: (<React.Fragment></React.Fragment>)
    });
}