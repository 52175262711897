import { Accordion, AccordionDetails, AccordionSummary, AppBar, Badge, Button, Chip, Container, Divider, Grid, IconButton, Menu, MenuItem, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from "@mui/material";
import PlayersController from "./controller/PlayersController";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalTemplate } from "../instances/modal";
import { formGroupTemplate, formTemplate } from "../instances/form";
import { useNavigate } from "react-router-dom";
import SearchBar from "../instances/searchBar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Players() {
    const controller: PlayersController = new PlayersController();
    const [ready, setReady] = useState(false);
    let navigate = useNavigate();

    let dataArray: any;
    let setDataArray: any;
    [dataArray, setDataArray] = useState([]);

    let players: any;
    let setPlayers: any;
    [players, setPlayers] = useState([]);

    let tempPlayers: any;
    let setTempPlayers: any;
    [tempPlayers, setTempPlayers] = useState([]);


    let queryedResults: any;
    let setQueryedResults: any;
    [queryedResults, setQueryedResults] = useState([]);

    const [showStandardPlayers, setShowStandardPlayers] = useState(true);

    const [deleteBuffer, setDeleteBuffer] = useState("");
    const [showNewPlayerForm, setShowNewPlayerForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPurgeModal, setShowPurgeModal] = useState(false);

    const [username, setUsername] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!ready) {
            getPlayerData();
            setReady(true);
        }
    }, [ready]);


    async function getPlayerData() {
        await controller.getData().then((data) => {
            const std = controller.parsePlayers(data).permPlayers;
            const tmp = controller.parsePlayers(data).tempPlayers;
            setPlayers([...std]);
            setTempPlayers([...tmp]);
            setDataArray(showStandardPlayers ? [...std] : [...tmp]);
            setQueryedResults(showStandardPlayers ? [...std] : [...tmp]);
        })
    }

    function switchPlayerType() {
        setDataArray(!showStandardPlayers ? [...players] : [...tempPlayers]);
        setQueryedResults(!showStandardPlayers ? [...players] : [...tempPlayers]);
    }

    function playerNameContainer(): JSX.Element {
        const cells = function (e: any) {
            return (
                <TableRow key={`${e._id}_user`}>
                    <TableCell>
                        {e.username}
                    </TableCell>
                    <TableCell>
                        {e.gameInProgress !== null ? e.gameInProgress : "None"}
                    </TableCell>
                    <TableCell align="right">
                        {actionMenu(e)}
                    </TableCell>
                </TableRow>
            )
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Player</TableCell>
                            <TableCell>GIP</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataArray.length > 0 ? queryedResults.map((e: any, i: number) => (cells(e))) : null}
                    </TableBody>
                </Table>
            </TableContainer >
        )
    }

    function actionMenu(element: any) {
        return (
            <Tooltip title="Actions" placement="right-start">
                <IconButton
                    aria-label="more"
                    id={element.id}
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setUsername(element.username);
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
        )
    }


    function actionMenuDropdown() {
        return (
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { navigate("/players/force_data/" + username) }}>Set Force Data</MenuItem>
                <MenuItem onClick={() => { navigate("/players/gameplay_options/" + username) }}>Set Gameplay Options</MenuItem>
                {/* <MenuItem href="#/action-2">Set Balance</MenuItem>
                    <MenuItem href="#/action-2">View Transactions</MenuItem>
                    <MenuItem href="#/action-2">Void Game In Progress</MenuItem> */}
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => { setShowDeleteModal(true); setDeleteBuffer(username) }}><FontAwesomeIcon icon={["fas", "trash-can"]} />&nbsp;Delete Player</MenuItem>

            </Menu>
        )
    }

    function createNewPlayerMenu() {
        return ModalTemplate({
            title: "Create New Player",
            body: undefined,
            confirmButtonString: "Add",
            confirmType: "success",
            confirmIcon: "add",
            form: formTemplate({
                formGroup: [[{
                    label: "Username",
                    id: "username",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Please Enter Name",
                    options: {
                        type: "string",
                        placeholder: "Name"
                    }
                }]],
                onSubmit: (data: any) => {
                    controller.handelNewPlayerCreateSubmit(data, {
                        setAnchorEl: setAnchorEl,
                        setShowNewPlayerForm: setShowNewPlayerForm,
                        setReady: setReady
                    })
                },
                submitString: "Submit",
            }),
            show: [showNewPlayerForm, setShowNewPlayerForm]
        })
    }

    function confirmPurgeTempPlayers() {
        return ModalTemplate({
            title: "Purge Old Temp Players",
            body: "Are you sure you want to Purge Old Temp Players?",
            confirmButtonString: "Delete",
            confirmType: "error",
            confirmIcon: "delete",
            show: [showPurgeModal, setShowPurgeModal],
            callback: () => {
                controller.handelPurgeOldTempPlayers({
                    setAnchorEl: setAnchorEl,
                    setShowPurgeModal: setShowPurgeModal,
                    setReady: setReady
                })
            }
        })
    }

    function deleteConfimation() {
        return ModalTemplate({
            title: "Delete Player",
            body: "Are you sure you want to delete this player?",
            confirmButtonString: "Delete",
            confirmType: "error",
            confirmIcon: "delete",
            show: [showDeleteModal, setShowDeleteModal],
            callback: (data: any) => {
                controller.handleDelete(data, {
                    setAnchorEl: setAnchorEl,
                    setShowDeleteModal: setShowDeleteModal,
                    setReady: setReady
                })
            }
        })
    }

    function newPlayerButton() {
        if (!showStandardPlayers) {
            return null;
        }
        return (
            <Button color="success" variant="outlined" type="submit" onClick={() => { setShowNewPlayerForm(true) }}><FontAwesomeIcon icon={["fas", "user-plus"]} />&nbsp;Create new Player</Button>
        )
    }

    function purgeOldTempPlayersButton() {
        if (showStandardPlayers) {
            return null;
        }
        return (
            <Button color="warning" variant="outlined" type="submit" startIcon={<DeleteSweepIcon />} onClick={() => { setShowPurgeModal(true) }}>Purge Old Temp Players</Button>
        )
    }


    function playerTypeToggle() {
        return (
            <ToggleButtonGroup
                color="primary"
                value={showStandardPlayers === true ? "players" : "temp"}
                exclusive
                onChange={(data: SyntheticEvent) => {
                    setShowStandardPlayers((data.currentTarget as HTMLInputElement).value === "players" ? true : false);
                    switchPlayerType()
                }}
                aria-label="Platform"
            >
                <ToggleButton value="temp"><Chip label={tempPlayers.length} />&nbsp;Temp Players </ToggleButton>
                <ToggleButton value="players"><Chip label={players.length} />&nbsp;Players </ToggleButton>
            </ToggleButtonGroup>
        )

    }

    function body(): JSX.Element {
        return (
            <Container>
                {actionMenuDropdown()}
                {playerTypeToggle()}
                <SearchBar dataSource={dataArray} output={setQueryedResults} fields={["username"]} limit={3} />
                {playerNameContainer()}
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="baseline"
                    spacing={2}
                    paddingTop={5}>
                    <Grid item>
                        {dataArray.length > 0 ? (<Typography>Player Count : {dataArray.length}</Typography>) : null}
                    </Grid>
                    <Grid item>
                        {newPlayerButton()}
                        {purgeOldTempPlayersButton()}
                    </Grid>
                </Grid>
                {createNewPlayerMenu()}
                {deleteConfimation()}
            </Container>
        )
    }

    { confirmPurgeTempPlayers() }
    return controller.renderLayout(null, body(), {
        title: "Players",
        helpString: (<React.Fragment>To create a new player simply click <strong>Create New Player</strong> and enter a name and then <strong>Submit</strong>. Once created, you can force scenarios for a particular player. First, select the game you wish to play and then you will then see a list of available scenarios. Tick the <ChevronRight /> button to add individual scenarios or use the checkboxes. When your selection is complete, click <strong>Set Data</strong> and you can use the forces on your chosen game. Setting <strong>‘Sticky Data</strong> to <strong>true</strong> will cause your listed selection of scenarios to loop. If left as <strong>false</strong>, the scenarios will play through then return to normal random selection.</React.Fragment>) as JSX.Element
    })
}