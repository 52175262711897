import React, { useEffect, useState } from "react";
import JackpotMessagesController from "./controller/JackpotMessagesController";
import { Autocomplete, Chip, ChipOwnProps, ChipPropsColorOverrides, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import JackpotGroupController from "../Jackpot/controller/JackpotGroupController";
import { IJackpotGroup } from "../Jackpot/data/JackpotGroupData";
import { EStatus, IJackpotMessage } from "./data/JackpotMessageData";
import { ILog } from "../ServerLogs/data/ServerLogsData";
import { OverridableStringUnion } from '@mui/types';


export default function JackpotMessages() {
    const controller = new JackpotMessagesController();
    const jackpotGroupController = new JackpotGroupController();
    const [ready, setReady] = useState<boolean>(false);
    const [jackpotGroupIDs, setJackpotGroupIDs] = useState<string[] | null>(null);
    const [jackpotMessages, setJackpotMessages] = useState<IJackpotMessage[] | null>(null);

    useEffect(() => {
        if (!ready) {
            getJackpotGroups();
            setReady(true);
        }
    }, [ready])

    async function getJackpotGroups() {
        await jackpotGroupController.getData().then((data: IJackpotGroup[] | null) => {
            setJackpotGroupIDs((data as IJackpotGroup[]).map((e: IJackpotGroup) => {
                return e.groupName;
            }));
        })
    }

    async function getJackpotMessages(groupID: string) {
        await controller.getData(groupID).then((data: IJackpotMessage[] | null) => {
            setJackpotMessages(data!.length > 0 ? (data as IJackpotMessage[]).map((e: IJackpotMessage) => {
                return e;
            }) : null);
        })
    }

    function JackPotMessages(): JSX.Element | null {
        if (!jackpotMessages) {
            return null;
        }

        let message = (e: IJackpotMessage, i: number) => {
            let color: OverridableStringUnion<
                'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                ChipPropsColorOverrides
            > = EStatus.SUCCESS ? "success" : "error";
            return (
                <TableRow key={`log_message_${i}`}>
                    <TableCell>
                        <Chip color="primary" label={e.groupCode} />
                    </TableCell>
                    <TableCell>
                        {e.winners}
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }}>{JSON.stringify(e.playerMessage)}</Typography>
                    </TableCell>
                    <TableCell>
                        {e.responseTimestamp}
                    </TableCell>
                    <TableCell>
                        <Chip color={color} label={e.status} />
                    </TableCell>
                </TableRow>
            )
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} >
                    <TableHead>
                        <TableRow>
                            <TableCell>Group</TableCell>
                            <TableCell>Winners</TableCell>
                            <TableCell>Player Message</TableCell>
                            <TableCell>Response Timestamp</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Exception</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jackpotMessages!.map((e: IJackpotMessage, i: number) => { return message(e, i) })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function JackpotGroupSelector(): JSX.Element | null {
        if (!jackpotGroupIDs) {
            return null;
        }
        return (
            <Grid item xs={8}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={jackpotGroupIDs.map((e: any) => { return e })}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Jackpot Groups" />}
                    onInputChange={(data) => {
                        getJackpotMessages((data.target as any).textContent);
                    }} />
            </Grid>
        )
    }

    function body(): JSX.Element {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <JackpotGroupSelector />
                    </Grid>
                    <Grid item xs={12}>
                        <JackPotMessages />
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Jackpot Messages",
        helpString: (<React.Fragment></React.Fragment>)
    })
}