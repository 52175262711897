import Controller from "../../Controller";

export default class PlayersPropertiesController extends Controller {

    constructor() {
        super();
        this.route = "admin/player_properties";

    }

    public getPlayerProperties(id: string, ready : any) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", params: id, full:false }).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }

    public updatePlayerProperties(id: string, newData: any, ready : any) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "POST", params: id}, {
                properties: newData
            }).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }
} 