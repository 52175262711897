import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import Splash from "../Splash";

export default class SplashController extends Controller {
    private widgets : JSX.Element[] = [];
    
    constructor() {
        super();
        this.route = ''
    }

    menu() : menuEntry {
        return {
            key: "splashMenu",
            name: "Splash",
            color : "#ff5741",
            style : "splash",
            link: "/",
        }
    }

    content() : contextEntry {
        return {
            key:"splashContext",
            title: "Splash",
            content: <Splash />,
            path : "/"
        }
    }
}