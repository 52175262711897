import { Box, Button, Chip, Container, FormControl, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import GameListController from "../controller/GameListController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IPaytable from "../data/GameListData";
import { ModalTemplate } from "../../instances/modal";
import { formTemplate } from "../../instances/form";
import { faL } from "@fortawesome/free-solid-svg-icons";
import React from "react";


interface ISelectedPoint {
    value: number,
    index: number
}

export default function GameListEditPricePoints() {
    let controller = new GameListController();

    let paytable: {};
    let setPaytable: any;
    [paytable, setPaytable] = useState({})

    let dataArray: any
    let setDataArray: any
    [dataArray, setDataArray] = useState([]);

    let editedDataArray: any;
    let setEditedDataArray: any;
    [editedDataArray, setEditedDataArray] = useState([]);

    let enabledDataArray: any
    let setEnabledDataArray: any
    [enabledDataArray, setEnabledDataArray] = useState([]);

    let gameData: any
    let setGameData: any
    [gameData, setGameData] = useState("");

    let selectedPoint: ISelectedPoint | {};
    let setSelectedPoint: any
    [selectedPoint, setSelectedPoint] = useState({});

    const [validated, setValidated] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [ready, setReady] = useState(false);
    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const params: any = useParams();
    let id: number = params.gameid

    useEffect(() => {
        if (!ready && dataArray.length === 0) {
            fetchData();
        }
        setReady(true);
    }, [ready])

    async function fetchData() {
        await controller.getPaytable(id).then((data) => {
            setPaytable(data);
            setDataArray((data as IPaytable).availablePrices);
            setEnabledDataArray((data as IPaytable).system.availablePrices);
            setGameData((data as IPaytable).name);
        });
    }

    function enableDisablePoint(id: number) {
        let tmp = dataArray;
        if (tmp.indexOf(id) !== -1) {
            tmp.splice(tmp.indexOf(id), 1);
        } else {
            tmp.push(id);
        }

        setDataArray(tmp);
        handleUpdatePricePointValue({ key: id, array: (tmp as []) });
    }

    function getEnabled(val: number): boolean {
        const point = (dataArray.find((e: number) => {
            return e === val;
        }) as number);

        return point !== undefined ? true : false;
    }

    function pricePointTable(): JSX.Element[] {
        return enabledDataArray.map((e: number, i: number) => {
            let disabled: boolean = !getEnabled(e);

            return (
                <TableCell key={`_point_${e}`}>
                    <FormControl fullWidth>
                        <TextField
                            type="text"
                            id={`${e}_value`}
                            defaultValue={e}
                            aria-describedby="pricePointValue"
                            disabled={disabled}
                            onChange={(data) => { handleUpdatePricePointValue({ key: e, ammount: !isNaN(parseInt(data.currentTarget.value)) ? parseInt(data.currentTarget.value) : 0 }) }}
                        />

                    </FormControl></TableCell>
            )
        })
    }

    function enableButtons(): JSX.Element[] {
        return enabledDataArray.map((e: number) => {
            if (getEnabled(e)) {
                return (
                    <TableCell onClick={() => { enableDisablePoint(e) }} key={`_enable_${e}`} ><Stack className="mx-auto"><Chip color="success" label="Enabled" /></Stack></TableCell>
                )
            } else {
                return (
                    <TableCell onClick={() => { enableDisablePoint(e) }} key={`_enable_${e}`} ><Stack className="mx-auto"><Chip color="error" label="Disabled" /></Stack></TableCell>
                )
            }
        })
    }
    function deleteButtons(): JSX.Element[] {

        const button = function (e: any, i: any) {
            return (
                <Box>
                    <Button onClick={() => { setShowDeleteModal(true); setSelectedPoint({ value: e, index: i } as ISelectedPoint); }} variant="outlined" size="large" color="error">Remove</Button>
                </Box>
            )
        }
        return enabledDataArray.map((e: number, i: number) => {
            return (
                <TableCell key={`_delete_${e}`}><Stack className="mx-auto"> {button(e, i)} </Stack></TableCell>
            )
        })
    }

    function handleDelete(): JSX.Element {
        return (<ModalTemplate
            title="Delete PricePoint"
            body={`Do you wish to Remove Price Point ${(selectedPoint as ISelectedPoint).value}?`}
            confirmButtonString="Remove"
            confirmType="error"
            confirmIcon="delete"
            callback={async () => {
                (dataArray as []).splice((selectedPoint as ISelectedPoint).index, 1);
                (paytable as IPaytable).availablePrices = dataArray;
                (paytable as IPaytable).system.availablePrices = dataArray;
                await controller.updatePaytable(id, paytable);

                setShowDeleteModal(false);
                setReady(false);
                setDataArray([]);
            }}
            show={[showDeleteModal, setShowDeleteModal]}
        />)
    }

    function handleCreateNewPricePoint() {
        return (<ModalTemplate
            title="Create PricePoint"
            body={`Do you wish to create a new pricePoint?`}
            confirmButtonString="Create New PricePoint"
            confirmType="success"
            confirmIcon="add"
            form={formTemplate({
                formGroup: [[{
                    label: "New Price Point Value",
                    id: "id",
                    feedbackSuccess: "This name looks ok!",
                    feedbackInvalid: "Enter a valid Price Value",
                    options: {
                        type: "number",
                        placeholder: "Please Enter Price Point value"
                    },
                }]],
                submitString: "Create",
                onSubmit: addNewPricePoint
            })}
            show={[showCreateModal, setShowCreateModal]}
        />)
    }

    function submitButton(): JSX.Element {
        return (
            <Button className="p-2 ms-auto" onClick={() => handleUpdateSubmit()} color="success">Save Changes</Button>
        )
    }

    async function addNewPricePoint(data: any) {
        let newDataArray = dataArray;
        newDataArray.push(parseInt(data[0].value));

        newDataArray.sort((a: number, b: number) => { return a - b });

        let tmp: IPaytable = (paytable as IPaytable);
        tmp.availablePrices = newDataArray;
        tmp.system.availablePrices = newDataArray;

        await controller.updatePaytable(id, tmp);

        controller.sendToastMessage({
            body: `Added new PricePoint ${data[0].value} to ${gameData}`,
            success: true
        });

        setShowCreateModal(false);
        setReady(false);
        setDataArray([]);
    }

    async function handleUpdateSubmit() {
        let tmp: IPaytable = (paytable as IPaytable);

        tmp.availablePrices = editedDataArray;
        tmp.system.availablePrices = editedDataArray;

        setEnabledDataArray(editedDataArray);
        await controller.updatePaytable(id, tmp);
        setDataArray([]);
        setReady(false);
        setShowSubmitButton(false);
        controller.sendToastMessage({
            body: `Saved changes to ${gameData}`,
            success: true
        });
    }

    async function handleUpdatePricePointValue(value: { key: number, ammount?: number, array?: [] }) {
        if (value.ammount !== undefined) {
            const tmp: number[] = enabledDataArray.map((element: number) => {
                return element === value.key ? value.ammount : element;
            });

            tmp.sort((a: number, b: number) => { return a - b });

            setEditedDataArray(tmp);
            setShowSubmitButton(true);
        }

        if (value.array !== undefined) {
            (paytable as IPaytable).availablePrices = value.array.filter((e: number) => { return e })
                .map((e: number) => { return e });

            await controller.updatePaytable(id, paytable);
            setReady(false);
        }
    }

    function body() {
        return (
            <Container>
                <h3>Game: {gameData !== "" ? gameData : null}</h3>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                {enabledDataArray.length > 0 ? enableButtons() : null}
                            </TableRow>
                            <TableRow>
                                {enabledDataArray.length > 0 ? pricePointTable() : null}
                            </TableRow>
                            <TableRow>
                                {enabledDataArray.length > 0 ? deleteButtons() : null}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack gap={3}>
                    <Grid container>
                        <Box sx={{ '& button': { m: 1 } }}>
                            <Button onClick={() => { setShowCreateModal(true); }} variant="outlined" size="large" color="primary">Create New Point</Button>
                        </Box>
                        {showSubmitButton ? submitButton() : null}
                    </Grid>
                </Stack>
                {handleDelete()}
                {handleCreateNewPricePoint()}
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Update Price Points",
        helpString: (<React.Fragment>When you add Price Points, please ensure these align with the data you have used when the game was added and set up. Once ready, enable and disable Price Points as required.</React.Fragment>)
    })

}