import MainContainerController from "./controller/MainContainerController";
import LoginScreenController from "../LoginScreen/controller/LoginScreenController";
import LoginScreen from "../LoginScreen/LoginScreen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AppBar, AppBarProps, Avatar, Box, Container, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItemButton, ListItemText, MenuItem, PaletteMode, Paper, ThemeProvider, Toolbar, createTheme, styled } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DataStatusMessage, { dataStatusMessageOptions } from "../instances/dataStatusMessage";
import { createContext, useMemo, useRef, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React from "react";
import { amber, blueGrey, grey } from "@mui/material/colors";
import { v4 as v4 } from 'uuid';
import { UserMenu } from "../instances/userMenu";


export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const drawerWidth: number = 240;

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        primary: {
            ...amber,
            ...(mode === 'dark' && {
                main: blueGrey[300],
            }),
        },
        ...(mode === 'dark' && {
            background: {
                default: blueGrey[900],
                paper: blueGrey[900],
            },
        }),
        text: {
            ...(mode === 'light'
                ? {
                    primary: grey[900],
                    secondary: grey[800],
                }
                : {
                    primary: '#fff',
                    secondary: grey[500],
                }),
        },
    },
});


export const appContext = createContext<React.Dispatch<React.SetStateAction<dataStatusMessageOptions | undefined>>>((message) => {console.log(message)});

export default function MainContainer() {
    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search);
    const redirect = queryParameters.get("redirect");
    if (redirect) {
        setTimeout(() => { navigate(redirect) }, 100);

    }
    

    const cssMenuClasses = ["defaultGroupMenu"];

    const controller = new MainContainerController();
    const loginScreenController = new LoginScreenController();
    const [loggedin, setLoggedInState] = useState(false);

    let messageQueue: dataStatusMessageOptions[];
    let setMessageQueue: any;
    [messageQueue, setMessageQueue] = useState([]);

    const [newMessage, setNewMessage] = useState<dataStatusMessageOptions | undefined>(undefined);

    const location = useLocation();

    useMemo(() => {
        if(newMessage) {
            const messages = [...messageQueue];
            messages.push({
                id: v4(),
                success: newMessage!.success,
                body: newMessage!.body,
                burn: burnMessages
            } as dataStatusMessageOptions);
            setMessageQueue(messages);
        }
    }, [newMessage]);

    const [mode] = React.useState<PaletteMode>('light');
    // Update the theme only if the mode changes
    const defaultTheme = createTheme(getDesignTokens(mode));


    function renderContext(data: any) {
        return (<Route path={data.path} key={data.key} element={data.content} />)
    }

    function burnMessages() {
        let messages = [...messageQueue];
        messages.shift();
        setMessageQueue(messages);
    }


    // function switchColor(cssClass: string) {
    //     cssMenuClasses.forEach((e) => {
    //         if (e !== cssClass) {
    //             (menuBarRef.current as any).classList.remove(e)
    //         } else {
    //             (menuBarRef.current as any).classList.add(e)
    //         }
    //     })
    // }

    function addCSSClassToArray(cssClass: string) {
        if (cssMenuClasses.indexOf(cssClass) === -1) {
            cssMenuClasses.push(cssClass);
        }
    }

    function addInitalClassToArray() {
        document.getElementById("MenuBar")?.classList.add(cssMenuClasses[0]);
    }

    function view() {
        return (
            <TransitionGroup>
                <CSSTransition
                    key={location.pathname}
                    timeout={200}
                    classNames="page"
                    unmountOnExit
                >
                    <Routes location={location}>
                        {controller.context().map((e: any) => renderContext(e))}
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
        )
    }


    // function footer() {

    //     let classes = "nav justify-content-end topInfo";

    //     if (Controller.dev) {
    //         classes = "nav justify-content-end construction"
    //     }

    //     return (
    //         <ul className={classes}>
    //             <li className="nav-item">
    //                 <span className="align-baseline">Development Response Server Admin</span>
    //             </li>
    //         </ul>
    //     )
    // }

    async function loginCheck() {
        if (await loginScreenController.checkUser()) {
            controller.loggedin = true;
        }
        setLoggedInState(controller.loggedin);
    }

    function loginScreenDisplay() {
        loginCheck();
        return (
            <div>
                <LoginScreen />
            </div>
        )
    }

    function displayMessage() {
        return (messageQueue.map((e: any, i: number) => {
            return <DataStatusMessage key={`_message_${i}`} id={e.id} body={e.body} success={e.success} burn={burnMessages} />
        }))
    }

    function viewContainer(view: () => JSX.Element): JSX.Element {
        if (location.pathname !== "/") {
            return (
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {view()}
                </Paper>
            )
        } else {
            return (
                <React.Fragment>
                    {view()}
                </React.Fragment>
            )
        }
    }

    function main() {
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <appContext.Provider value={setNewMessage}>
                    <UserMenu controller={controller} />
                    <Outlet />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                            <Grid container spacing={12}>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    {viewContainer(view)}
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    {messageQueue.length > 0 ? displayMessage() : null}
                </appContext.Provider>
            </Box >

        )
    }



    return (
        <ThemeProvider theme={defaultTheme}>
            {loggedin ? main() : loginScreenDisplay()}
            {/* {main()} */}
        </ThemeProvider >
    );
}