import { Accordion, AccordionSummary, AppBar, AppBarProps, Avatar, Divider, Drawer, Grid, IconButton, List, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, styled } from "@mui/material";
import { appContext, drawerWidth } from "../root/MainContainer";
import { useContext, useState } from "react";
import MainContainerController from "../root/controller/MainContainerController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import Controller from "../Controller";
interface DRSAppBarProps extends AppBarProps {
    open?: boolean;
}

const DRSAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<DRSAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const DRSDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export function UserMenu(props: { controller: MainContainerController }) {

    const sendToastMessage = useContext(appContext);

    const [open, setOpen] = useState(false);
    const [drawerSelection, setDrawerSelecton] = useState("");

    const toggleDrawer = () => {
        setOpen(!open);
        setDrawerSelecton("");
    };

    const navigate = useNavigate();

    const copyTokenMenuAction = (close: () => void) => {
        navigator.clipboard.writeText(Controller.getCookies("sessionToken"));
        sendToastMessage({
            body: `Token : ${Controller.getCookies("sessionToken")} copied to clipboard! `,
            success: true
        });
        close();
    }

    const logoutAction = (close: () => void) => {
        sendToastMessage({
            body: `Logging Out...`,
            success: true
        });
        close();
    }

    function menuActions() {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <React.Fragment>
                <MenuItem onClick={handleClick}>
                    <Avatar src={decodeURIComponent(Controller.getCookies("userAvatar"))} sx={{ marginRight: "5px" }} />&nbsp;{Controller.getCookies("user")}
                </MenuItem>
                <Menu
                    id="positioned-menu"
                    aria-labelledby="positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem onClick={()=>{logoutAction(handleClose)}}>Logout</MenuItem>
                    <MenuItem onClick={()=>{copyTokenMenuAction(handleClose)}}>Copy Token</MenuItem>
                </Menu>
            </React.Fragment>
        )
    }

    function topMenu() {
        return (
            <DRSAppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            {menuActions()}
                        </Grid>
                    </Grid>
                </Toolbar>
            </DRSAppBar>
        )
    }

    function contextMenu() {
        let headers: any = [];
        headers = props.controller.menu().map((group: any) => {
            const row = (group.components).map((e: any) => {
                return (
                    <ListItemButton alignItems="flex-start" key={`${group.name}-${e.key}_menuItem`} onClick={() => { navigate(e.link) }}>
                        <ListItemText primary={e.name} />
                    </ListItemButton>
                )
            });

            function checkIfDrawOpened(): boolean | undefined {
                return open && drawerSelection === group.name;
            }

            return (
                <React.Fragment key={group.name}>
                    <Accordion expanded={checkIfDrawOpened()}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={group.icon} />}
                            aria-controls={`${group.name}_content`}
                            id={`${group.name}_id`}
                            onClick={() => { setOpen(true); setDrawerSelecton(group.name); }}
                        >
                            {open ? group.name : null}
                        </AccordionSummary>
                        <Divider />
                        {row.map((e: any) => (e))}
                    </Accordion>
                </React.Fragment>
            )
        })
        return (
            <DRSDrawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {headers.map((e: any) => (e))}
                </List>
            </DRSDrawer>
        )
    }
    return (
        <React.Fragment>
            {contextMenu()}
            {topMenu()}
        </React.Fragment>
    )
}
