import ConfigController from '../../Config/controller/ConfigController';
import Controller from '../../Controller';
import GaffController from '../../Gaff/controller/GaffController';
import GameListController from '../../GameList/controller/GameListController';
import GlobalDatasetsController from '../../GamePlayOptions/controller/GamePlayOptionsController';
import GlobalTransactionHistoryController from '../../GlobalTransactionHistory/controller/GlobalTransactionHistoryController';
import { contextEntry } from '../../IComponent';
import ImporterController from '../../Importer/controller/ImporterController';
import JackpotGroupsController from '../../Jackpot/controller/JackpotGroupController';
import JackpotMessagesController from '../../JackpotMessages/controller/JackpotMessagesController';
import PermissionsController from '../../Permissions/controller/PermissionsController';
import PlayersController from '../../Players/controller/PlayersController';
import ServerLogsController from '../../ServerLogs/controller/ServerlogsController';
import SplashController from '../../Splash/controller/SplashController';

export default class MainContainerController extends Controller {
    splashController: SplashController = new SplashController();
    importController: ImporterController = new ImporterController();
    gamelistController: GameListController = new GameListController();
    configController: ConfigController = new ConfigController();
    serverLogsController: ServerLogsController = new ServerLogsController();
    players: PlayersController = new PlayersController();
    globalTransactionHistory: GlobalTransactionHistoryController = new GlobalTransactionHistoryController();
    gaffData: GaffController = new GaffController();
    permissions: PermissionsController = new PermissionsController();
    globalDatasets: GlobalDatasetsController = new GlobalDatasetsController();
    jackpotGroups: JackpotGroupsController = new JackpotGroupsController();
    jackpotMessages: JackpotMessagesController = new JackpotMessagesController();

    loggedin: boolean = false;

    public menu(): object[] {
        return [{
            'name': 'Games',
            'components': [
                this.gamelistController.menu(),
                this.importController.menu(),
                this.gaffData.menu(),
            ],
            'icon': ['fas', 'dice'],
            'CSS': 'dataGroupMenu'
        },
        // {
        //     'name': 'Jackpot',
        //     'components': [
        //         this.jackpotGroups.menu(),
        //         this.jackpotMessages.menu()
        //     ],
        //     'icon': ['fas', 'vault'],
        //     'CSS': 'analysticsGroupMenu'
        // }, 
        {
            'name': 'Player Data',
            'components': [
                this.players.menu(),
                this.globalDatasets.menu(),
                // this.globalTransactionHistory.menu()
            ],
            'icon': ['fas', 'chalkboard-user'],
            'CSS': 'settingsGroupMenu'
        }, {
            'name': 'Analytics',
            'components': [
                this.serverLogsController.menu(),
            ],
            'icon': ['fas', 'chart-gantt'],
            'CSS': 'analysticsGroupMenu'
        },
        {
            'name': 'Settings',
            'components': [
                this.configController.menu(),
                // this.permissions.menu()
            ],
            'icon': ['fas', 'cog'],
            'CSS': 'settingsGroupMenu'
        }];
    }

    private addSubPagesToContext(): contextEntry[] {
        const contextArray: contextEntry[] = [];
        contextArray.push(
            ...this.gamelistController.subpageData,
            ...this.players.subpageData
        );
        return contextArray;
    }

    public context(): contextEntry[] {
        let contenxtArray: contextEntry[] = [
            this.gaffData.content(),
            this.importController.content(),
            this.configController.content(),
            this.gamelistController.content(),
            this.players.content(),
            this.permissions.content(),
            this.globalDatasets.content(),
            this.jackpotGroups.content(),
            this.jackpotMessages.content(),
            this.splashController.content(),
            this.serverLogsController.content()
        ]
        const subPages = this.addSubPagesToContext();
        contenxtArray = contenxtArray.concat(subPages);
        return contenxtArray;
    }

    public static buttonStyle(menu: any) {
        return `button-${menu.style}`
    }
}