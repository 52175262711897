import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import JackpotGroups from "../JackpotGroups";
import { IJackpotGroup, IJackpotHandlerOptions, createJackpotGroupRequest } from "../data/JackpotGroupData";
import { IResponse } from "../../root/data/MainContainerData";

export default class JackpotGroupController extends Controller {
    
    constructor() {
        super();
        this.route = 'admin/jackpot'
    }

    public getData() : Promise<IJackpotGroup[] | null> {
        return new Promise<IJackpotGroup[] | null>(async (resolve, reject) => {
            await this.sendRequest({ method: "GET"}).then((data : IResponse) => {
                const acceptedData = (check:IJackpotGroup[]) : check is IJackpotGroup[] => {
                    return data.responseData.data;
                }
                return resolve(acceptedData(data.responseData.data) ? data.responseData.data as IJackpotGroup[] : null);
            }).catch((err) => {
                return reject(err as string);
            })
        });
    }

    public createNewGroup(data : createJackpotGroupRequest) : Promise<IJackpotGroup[] | string> {
        return new Promise<IJackpotGroup[] | string>(async (resolve, reject) => {
            await this.sendRequest({ method: "POST", params : data.code},{
                name : data.name
            }).then((data : IResponse) => {
                return resolve(data.responseData.data as IJackpotGroup[]);
            }).catch((err) => {
                return reject(err as string);
            })
        });
    }

    public updateGroup(id : string, data : string) : Promise<IJackpotGroup[] | string> {
        return new Promise<IJackpotGroup[] | string>(async (resolve, reject) => {
            await this.sendRequest({ method: "PUT"}).then((data : IResponse) => {
                return resolve(data.responseData.data as IJackpotGroup[]);
            }).catch((err) => {
                return reject(err as string);
            })
        });
    }

    public deleteGroup(id : string) : Promise<IJackpotGroup[] | string> {
        return new Promise<IJackpotGroup[] | string>(async (resolve, reject) => {
            await this.sendRequest({ method: "DELETE", params : id}).then((data : IResponse) => {
                return resolve(data.responseData.data as IJackpotGroup[]);
            }).catch((err) => {
                return reject(err as string);
            })
        });
    }

     public async submitCreateNewGroup(callbackData: { data: any }[],options : IJackpotHandlerOptions) {
        let body: string = "";
        let success: boolean = false;
        await this.createNewGroup({
            code: callbackData[0].data,
            name: callbackData[1].data
        }).then((data: any) => {
            body = data;
            success = true;
        }).catch((data: any) => {
            body = data;
            success = false;
        }).finally(() => {
            this.sendToastMessage({
                body: body,
                success: success
            });
            options.setShowConfirmModal!(false);
            options.setReady!(false);
        })
    }

    
    public async deleteKey(groupData : any, options: IJackpotHandlerOptions) {
        let body: string = "";
        let success = false;

        await this.deleteGroup(groupData)
            .then((data: any) => {
                body = data;
                success = true;
            })
            .catch((data: any) => {
                body = data;
                success = false;
            })
            .finally(() => {
                this.sendToastMessage({
                    body: body,
                    success: success
                });
                options.setReady!(false);
                options.setShowDeleteModal!(false);
            });
    }

    menu() : menuEntry {
        return {
            key: "jackpotGroupMenu",
            name: "Jackpot Groups",
            color : "#ff5741",
            style : "jackpot",
            link: "/jackpotGroups",
        }
    }

    content() : contextEntry {
        return {
            key:"jackpotGroupContext",
            title: "Jackpot Groups",
            content: <JackpotGroups controller={this} />,
            path : "/jackpotGroups"
        }
    }
}