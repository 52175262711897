import { useState } from "react";
import ImporterController from "./controller/ImporterController";
import { Container } from "@mui/material";
import { ModalTemplate } from "../instances/modal";
import ImporterForm from "./instances/importerForm";
import React from "react";

export default function Importer() {
    const controller: ImporterController = new ImporterController();
    const [showImportFormConfirm, setShowImportFormConfirm] = useState<boolean>(false);
    const [data,setData] = useState<FormData>();

    async function handleSubmit() {
        let body: string = "";
        let success: boolean = false;
        await controller.sendData(data)
            .then((data: any) => {
                body = data.responseData.data;
                success = false;
            }).catch((data: any) => {
                body = data.responseData.data;
                success = false;
            }).finally(() => {
                controller.sendToastMessage({
                    body: body,
                    success: success
                });
                setShowImportFormConfirm(false);
            });
    }

    function importFormConfirm() {
        return <ModalTemplate
            title="Confirm Import Data?"
            confirmButtonString="Confirm"
            confirmType="success"
            callback={handleSubmit}
            show={[showImportFormConfirm, setShowImportFormConfirm]}
        />
    }

    function importForm(): JSX.Element {
        return <ImporterForm
            confirm={setShowImportFormConfirm}
            data={{
                get: data as FormData,
                set: setData as React.Dispatch<React.SetStateAction<FormData>>, 
            }}
            controller={controller}
        />
    }

    function body() {
        return (
            <Container>
                {importFormConfirm()}
                {importForm()}
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "Create New Game",
        helpString: (<React.Fragment>To add and create a new game, you will need a <strong>Game ID</strong> in the format XXXX and a simple <strong>Game Name</strong>. You can then upload the <strong>prizeStructure.csv</strong> and <strong>prizeTable.csv</strong> from the game’s SGD repo (Or create your own .csv files based on your GDD data – see separate documentation). A mechanic file can be uploaded, but if this is not available the game can be set up with a default empty scenario. Scenarios must then be entered manually from <strong>Game List/Update Scenarios</strong>. When ready, click <strong>Submit</strong>.</React.Fragment>)
    });
}