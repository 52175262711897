import { Box, FormControl, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";

export interface IProps {
    dataSource: any,
    output: any,
    fields: string[]
    limit: number,
    modifyer?: number
}

export default function SearchBar(props: IProps) {

    let dataSource = props.dataSource;
    let output = props.output;
    let fields = props.fields;
    let limit = props.limit;

    let results: any[] = [];

    let [search, setSearch] = useState<string | null>(null);
    let [modifiyer, setModifyer] = useState<number>(0);

    useEffect(()=>{
        let outputvalue : any[] | null = null;
        if (search !== null) {
            dataSource.forEach((e: any) => {
                fields.forEach((el) => {
                    if (e[el].toString().substring(0, (e[el].toString().length - modifiyer)).toLowerCase() === search) {
                        results.push(e);
                    }
                })
            })

            if (results.length < 1) {
                setModifyer((value) => (value - 1));
            }

            outputvalue = results;
        }
        if(search === "" || search == null) {
            outputvalue = dataSource;
        }
        output(outputvalue);
    },[search]);
    
    function submitSearch(event: any) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
        }

        setModifyer(props?.modifyer ? props.modifyer : 0);

        if (form.value === "") {
            setSearch(null)
        } else {
            setSearch(form.value.toLowerCase())   
        }
        setSearch(form.value.toLowerCase())
    }

    return (
        <Box padding={2} paddingBottom={5} paddingTop={5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl fullWidth>
                <TextField onChange={(data: any) => { submitSearch(data) }} id="input-with-sx" label="Search...." variant="standard" />
            </FormControl>
        </Box>
    )
}