import { Grid } from "@mui/material";
import LoginScreenController from "./controller/LoginScreenController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LoginScreen() {
    const controller = new LoginScreenController();

    controller.loginURL();

    function errorMessage() {
        return (
            <Grid>
                <h2>Error Logging In!</h2>
                <span>{controller.errorReson}</span>
            </Grid>
        )
    }

    return (
        <div>
            <Grid>
                {controller.showErrorMessage ? errorMessage() : null}
            </Grid>
        </div>
    )
}
