import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import GameList from "../GameList"
import GameListEditScenario from "../views/subviews/GameListEditScenario";
import IPaytable, { IMechanic, IScenario } from "../data/GameListData";
import GameListEditPricePoints from "../views/GameListEditPricePoints";
import { IResponse } from "../../root/data/MainContainerData";

export default class ImporterController extends Controller {
    public data: any;
    constructor() {
        super();
        this.route = "admin/gamelist";

        this.subpageData = [
            {
                key: "gameListEditScenarioContext",
                title: "gameListEditScenario",
                content: <GameListEditScenario />,
                path: "/gameListEditScenario/:gameid"
            },
            {
                key: "gameListEditPricePointsContext",
                title: "gameListEditPricePoints",
                content: <GameListEditPricePoints />,
                path: "/gameListEditPricePoints/:gameid"
            },
        ]
    }

    public async getData() : Promise<{name: string, id:number}[] | string>  {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", full:false}).then((data : IResponse | []) => {
                return resolve((data as {name: string, id:number}[]));
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public async getPaytable(id: number | null) : Promise<IPaytable | string> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "GET",
                params: `${id}`,
                full:false
            }).then((data : IResponse | IPaytable) => {
                return resolve((data as IPaytable));
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public async duplcate(id: number, duplicationID: number, name: any, copyMechanics: boolean) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                params: `${id}`,
            }, {
                originalID : duplicationID,
                gameName : name,
                copyMechanics : copyMechanics
            }).then((data: any) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public async updatePaytable(id: number | null, data: any) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "PUT",
                params: `${id}`,
            }, data).then((data: IResponse) => {
                resolve(data.responseData as unknown as IPaytable);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    public async getMechanic(id: number | null, skip: number) : Promise<{results : IScenario[], total: number} | string> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "GET",
                url: "admin/scenario",
                params: `${id}`,
                query:[{
                    name:"skip",
                    value: skip.toString()
                }],
                full : false
            }).then((data: any) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public async updateMechanic(id: number, data: {scenario: IScenario & {_id : string }}[]) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                url: "admin/mechanic",
                params: `${id}`
            }, {scenario : data}).then((data: IResponse) => {
                resolve(data.responseData);
            }).catch((err) => {
                reject(err);
            })
        });
    }

    public async overWritePrizeTable(gameID: string, data: FormData) : Promise<string> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                url: "consume/prizeTable",
                files: true,
                params : gameID
            },data).then((data: IResponse) => {
                return resolve(data.responseData.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public async overWritePrizeStructure(gameID: string, data: FormData) : Promise<string> {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                url: "consume/prizeStructure",
                files: true,
                params : gameID
            }, data).then((data: IResponse) => {
                return resolve(data.responseData.data);
            }).catch((err) => {
                reject(err);
            })
        });
    }

    public async createScenario(data: {gameID : number, scenario: IScenario}) {
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({
                method: "POST",
                url: "admin/mechanic",
            }, data).then((data: IResponse) => {
                resolve(data.responseData);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    menu(): menuEntry {
        return {
            key: "gameListMenu",
            name: "Game List",
            style: "dataGroupMenu",
            color: "#ff5741",
            link: "/gameList",
        }
    }

    content(): contextEntry {
        return {
            key: "gameListContext",
            title: "Game List",
            content: <GameList />,
            path: "/gameList"
        }
    }
}

