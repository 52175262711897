export enum ELogLevel {
    message = "message",
    info = "info",
    warning = "warning",
    error = "error",
}

export interface ILog {
    message? : string,
    level? : ELogLevel,
    timestamp? : Date,
    user? : string
    skip? : number
}