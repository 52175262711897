export interface IJackpotMessage {
    groupCode : string,
    winners: number | null,
    playerMessage: {
        messages : IJackPotMessageValue[]
        id : number
    },
    responseTimestamp: number,
    status:EStatus,
    exception: string | null
}

interface IJackPotMessageValue {
    id : number
    locale : string
    text : EMessage
    currency : string
}

export enum EStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

export enum EMessage { 
    newJackpot = "This is a new Jackpot there has been no winners yet",
    win = "Someone just won %a",
    winMinute = "Someone just won %a, %e minutes ago",
    winHour = "Someone just won %a, %e hour ago",
    winHours = "Someone just won %a, %e hours ago",
    winDay = "Someone just won %a, %e day ago",
    winDays = "Someone just won %a, %e days ago",
    topWinMinute = "Someone just won the top jackpot %a, %e minutes ago",
    topWinHour = "Someone just won the top jackpot %a, %e hour ago",
    topWinHours = "Someone just won the top jackpot %a, %e hours ago",
    topWinDay = "Someone just won the top jackpot %a, %e day ago",
    topWinDays = "Someone just won the top jackpot %a, %e days ago",
    freePlay = "Free Play",
    notEligabile = "Not eligible to win progressive jackpot"
}
