import { contextEntry, menuEntry } from "../../IComponent"
import Controller from "../../Controller";
import ServerLogs from "../Serverlogs";
import { ILog } from "../data/ServerLogsData";

export default class ServerLogsController extends Controller {
    constructor() {
        super();
        this.route = 'admin/logs'
    }

    getData(query? : ILog) {
        let payload : string = "";
        let makeQuery = (i : number)=>{
            if(i===1){return '&'} else {return "?"}
        }
        if(query) {
            payload = Object.keys(query as ILog).map((e : string,i:number)=>{return `${makeQuery(i)}${e}=${(Object.values(query)[i])}`}).toString().replace(",","");
        }        
        return new Promise(async (resolve, reject) => {
            await this.sendRequest({ method: "GET", full:false, params : payload }).then((data) => {
                return resolve(data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }

    menu() : menuEntry {
        return {
            key: "serverLogsMenu",
            name: "Server Logs",
            color : "#ff5741",
            style : "analyticsGroupMenu",
            link: "/serverlogs",
        }
    }

    content() : contextEntry {
        return {
            key:"serverLogsContext",
            title: "Server Logs",
            content: <ServerLogs />,
            path : "/serverlogs"
        }
    }
}