import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, Grid, InputProps, MenuItem, Paper, Select, Switch, TextField, Typography, styled } from "@mui/material";
import React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { v4 as uuidv4, v4 } from 'uuid';
import { Id } from "react-beautiful-dnd";

export interface formOptions {
    validationController?: boolean | undefined,
    formGroup?: formGroupOptions[][]
    rows?: number | undefined,
    onSubmit?: any,
    onUpdate?: any,
    submitString?: string,
    insert?: any,
    dynamicFormData?: {
        get: any,
        set: any
    }
}
export interface formGroupOptions {
    label: string,
    id: string,
    default?: any,
    feedbackSuccess: string,
    feedbackInvalid: string,
    options: any,
    style?: CSSProperties,
    classes?: string,
    rowIndex?: number
    selectGroupOptions?: selectGroupOption[]
}

export interface selectGroupOption {
    name: string
    value: string
}

export interface IformData {
    id: string,
    data: any
}

export function formTemplate(options: formOptions) {

    let formID: string = `form_${v4()}`;

    let formData: IformData | []
    let setFormData: React.Dispatch<React.SetStateAction<IformData | []>>
    [formData, setFormData] = useState<IformData | []>([]);

    // if (options.dynamicFormData) {
    //     formData = options.dynamicFormData!.get;
    //     setFormData = options.dynamicFormData!.set;
    // } else {
    // }

    function formRows(): JSX.Element {
        let groups: any = [];
        let index: number = 0;

        if (!options.dynamicFormData) {
            useCallback(()=>{
                submitForm(null, options.onUpdate, {
                    formDataCollection: {
                        get: formData,
                        set: setFormData
                    }
                })
            },[formData])
        }

        if (options.formGroup !== undefined) {
            for (let [key, value] of Object.entries(options.formGroup)) {
                groups[index] = value.map((e) => {
                    return formGroupTemplate(e, {
                        formDataCollection: {
                            get: formData,
                            set: setFormData
                        }
                    })
                })
                index++;
            }
        }
        return (
            <React.Fragment>
                <form id={formID}>
                    {groups.map((e: any, i: number) => {
                        return (
                            <Grid key={`${i}_group`} container columns={{ xs: 8, sm: 8, md: 8 }}>
                                {e}
                            </Grid>
                        )
                    })}
                </form>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }} onSubmit={(event) => {
                submitForm(event, options.onSubmit, {
                    formDataCollection: {
                        get: formData,
                        set: setFormData
                    }
                })
            }}>
                {formRows()}
            </Box>
            <Box sx={{ '& button': { m: 1 } }}>
                <Button form={formID} onClick={(event) => {
                    submitForm(event, options.onSubmit, {
                        formDataCollection: {
                            get: formData,
                            set: setFormData
                        }
                    })
                }} variant="outlined" size="large" color="success" type="submit"><FontAwesomeIcon icon={["fas", "note-sticky"]} />&nbsp;{options.submitString}</Button>
            </Box>
        </React.Fragment>
    )
}

export function formGroupTemplate(options: formGroupOptions, hooks: { formDataCollection: { set: any, get: any } }, id?: string): JSX.Element {
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    let form: JSX.Element = (<React.Fragment></React.Fragment>);

    prepareNewDataArray(options, hooks, options.default, id);

    switch (options.options.type) {
        case "number":
        case "string": {

            form = <TextField id={options.id}
                label={options.label}
                helperText={options.feedbackInvalid}
                type={options.options.type}
                fullWidth
                margin="normal"
                onChange={(data: any) => setData(options, hooks, data.target.value)}
                defaultValue={options.default}
            />
            break;
        }
        case "file": {
            let fileExists: IformData | undefined = (hooks.formDataCollection.get as IformData[]).find((e: IformData) => { if (e.id === options.id && e.data !== null) return e });
            form = <React.Fragment>
                <Box>
                    <Typography marginTop={2} gutterBottom>{options.label}</Typography>
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        Upload file
                        <VisuallyHiddenInput id={options.id}
                            onChange={(data: any) => setData(options, hooks, data.target.files[0])}
                            type="file" />
                    </Button>
                    <Typography marginTop={0.5} marginBottom={2} variant="caption" display="block" gutterBottom>{fileExists?.data.name == null ? "Nothing Selected" : fileExists?.data.name
                    }</Typography>
                </Box>
            </React.Fragment>
            break;
        }
        case "switch": {
            form = <FormControlLabel control={<Switch defaultValue={"off"}
                onChange={(data: any) => setData(options, hooks, data.target.value)}
                id={options.id} defaultChecked />} label={options.label} />
            break;
        }
        case "hidden": {
            <VisuallyHiddenInput id={options.id}
                defaultValue={options.default}
                onChange={(data: any) => setData(options, hooks, data.target.value)}
                type="string" />
        }
    }
    
    return (

        // <Grid fullWidth item key={`${options.id}_group_element`}>
        <FormControl id="myform" key={`${options.id}_group_element`} fullWidth>
            {form}
        </FormControl>
        // </Grid>
    )
}

function submitForm(event: any, callback: any, hooks: {
    formDataCollection: { set: any, get: any }
}
): any {
    if (event !== null) {
        event.preventDefault();
        event.stopPropagation();
    }

    const data = hooks.formDataCollection.get;
    if (data.length > 0) {
        callback(data);
    }
}

function prepareNewDataArray(options: formGroupOptions, hooks: {
    formDataCollection: { set: any, get: any }
}, data: any, id?: Id) {
    if ((hooks.formDataCollection.get as IformData[]).find((e: IformData) => {
        if (`${e.id}` === `${options.id}`) {
            if (data && !e.data) {
                e.data = data;
            }
            return e;
        }
    }) == null) {
        hooks.formDataCollection.set([...hooks.formDataCollection.get, { id: `${options.id}`, data: null }]);
    }
}

function setData(options: formGroupOptions, hooks: {
    formDataCollection: { set: any, get: any }
}, data: any) {
    let newData: IformData[] = (hooks.formDataCollection.get as IformData[]).map((e: IformData) => {
        if (`${e.id}` === `${options.id}`) {
            if (data !== null) {
                e.data = data;
            }
        }
        return e;
    });
    hooks.formDataCollection.set([...hooks.formDataCollection.get], newData);

    return data
}
