import { CloudUpload, SystemUpdateAlt } from "@mui/icons-material";
import { Box, Grid, FormControl, InputLabel, FilledInput, Typography, FormControlLabel, Switch, styled, Button } from "@mui/material";
import React, { useEffect } from "react";
import { IformData } from "./form"
import { useForm } from "react-hook-form";
import Controller from "../Controller";

export interface fileDropFormProps {
    title: string,
    message: string,
    name: string,
    fileName : string
    controller: Controller,
    confirm: React.Dispatch<React.SetStateAction<boolean>>,
    submit : (data: FormData) => Promise<void>
}

type formData = {
    file: File | null
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

export function FileDropForm(props: fileDropFormProps) {
    const {
        register,
        watch,
        setError,
        setValue,
        getValues,
        handleSubmit,
        formState,
        clearErrors
    } = useForm<formData>({
        defaultValues: {
            file: null
        }
    });

    useEffect(() => {
        if (formState.isValidating) {
            checkForFileErrors()
        }
    }, [formState]);

    const watchFile = watch("file");

    const onSubmit = async () => {
        let correctFiles = checkForFileErrors();
        if (correctFiles) {
            const formData = new FormData();
            getValues();
            formData.append(props.fileName, getValues().file as File);
            await props.submit(formData).then(()=>{
                props.confirm(false);    
            }).catch((error: any)=>{
                props.controller.sendToastMessage({
                    body: `${error}`,
                    success: false
                });
            });
        }
    }
    const addFile = (data: any): File => {
        let file: File = data.target.files.item(0);
        return file;
    }


    const checkForFileErrors = (): boolean => {
        if (getValues().file ) {
            if(getValues().file?.type !== 'text/csv') {
                setError("file", {
                    type: "manual",
                    message: "File is not CSV",
                })
                return false;
            }
            clearErrors("file");
            return true;
        }
        setError("file", {
            type: "manual",
            message: "File is not set",
        })
        return false;
    }


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box paddingBottom={3} sx={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        
                        container
                    >
                        <Grid item xs>
                            <Typography textAlign={"center"} variant="h4">{props.title}</Typography>
                            <Typography marginBottom={2} textAlign={"center"} variant="body1">{props.message}</Typography>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth variant="filled">
                                <Typography gutterBottom></Typography>
                                <Button {...register(`file`)} component="label" variant="contained" startIcon={<CloudUpload />}>
                                    Upload file
                                    <VisuallyHiddenInput id={"file"}
                                        onChange={(data: any) => { setValue("file", addFile(data)); checkForFileErrors() }}
                                        type="file" />
                                </Button>
                                <Typography textAlign={"center"} marginTop={0.5} marginBottom={0} variant="caption" display="block" gutterBottom>{watchFile !== null ? watchFile.name : "Nothing Selected"}</Typography>
                                <Typography textAlign={"center"} sx={{ color: 'red' }} variant="caption" gutterBottom>{formState.errors.file?.message?.toString()}</Typography>
                            </FormControl>
                        </Grid>
                    </Grid >
                </Box >
                <Grid
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    container
                >
                    <Grid marginTop={3} item>
                        <Button type="submit" variant="contained" color="primary" startIcon={<SystemUpdateAlt />}>Submit</Button>
                    </Grid>
                    <Grid marginTop={3} item>
                        <Button variant="contained" color="primary" onClick={()=>{props.confirm(false)}} >Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment >
    )
}