import { Container } from "@mui/material";
import PermissionsController from "./controller/PermissionsController"
import React from "react";

export default function Permissions() {
    const controller = new PermissionsController();
    
    function body() {
        return (
            <Container>
                
            </Container>
        )
    }

    return controller.renderLayout(null, body(), {
        title: "User Permissions",
        helpString: (<React.Fragment></React.Fragment>)
    })
}